import styled from 'styled-components'

export const Table = styled.table`
    margin-top:30px;
    border-spacing: 0;
    border-collapse: collapse;
    
`
export const TrHead = styled.tr`
    background-color: #01423C;
`
export const Tr = styled.tr<{ hidden?: boolean }>`
    background-color: Gray;
    ${ props => props.hidden === true ? "display: none;" : ""}
`
export const Th = styled.th`
    color: #616161;
    background-color: #fafafa;
    font-weight: 500;
    border: 1px solid #eee;
    font-family: Roboto,sans-serif;
    box-sizing: border-box;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 18px;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
`

export const Td = styled.td<{bgColor?: string, clickable?: boolean, rightAlign?: boolean, paddingLr?: number }>`
    color: #424242;
    background-color: ${ props => props.bgColor ? props.bgColor : "#fff" };
    font-weight: 500;
    border: 1px solid #eee;
    font-family: Roboto,sans-serif;
    box-sizing: border-box;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 18px;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: ${ props => props.paddingLr ?? '10'}px;
    padding-left: ${ props => props.paddingLr ?? '10'}px;
    white-space: nowrap;
    ${ props => ( props.clickable === true ) ? "cursor: pointer;" : "" }
    ${ props => ( props.rightAlign === true ) ? "text-align: right;" : "" }
`

export const TableControls = styled.div`

`
export const TableControlItem = styled.div`
    
`
export const ControlLabel = styled.div`
    width: 100%;
    color: #424242;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    box-sizing: border-box;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom:10px;
`
export const BtnStatus = styled.button<{ highlight: boolean }>`
    background-color: ${ props => props.highlight ? "#35C764": "#fafafa"};
    color: ${ props => props.highlight ? "#fff": "#616161"};
    display: inline-block;
    width: 110px;
    font-weight: 500;
    border: 1px solid #eee;
    font-family: Roboto,sans-serif;
    box-sizing: border-box;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 56px;
    line-height: 56px;
    padding-right: 16px;
    padding-left: 16px;
`