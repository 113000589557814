export function unixToDateTimeString(unix: number | undefined) {
    if( unix === undefined || unix < 1 ) return "-"
    var localDate = new Date();
    var utcOffset = localDate.getTimezoneOffset();
    return (new Date(unix*1000 - utcOffset * 60e3)).toISOString().replace('.000Z','')
}

export function unixToDateTimeStringTwoLines(unix: number | undefined): [string,string] {
    if( unix === undefined || unix < 1 ) return ["-", ""]
    var localDate = new Date();
    var utcOffset = localDate.getTimezoneOffset();

    const splitString = (new Date(unix*1000 - utcOffset * 60e3)).toISOString().replace('.000Z','').split('T')
    return [splitString[0], splitString[1] ?? '']
}

export function twoDigitString ( num: number ) {
    if( num === 0) return '-'
    return (num / 100).toLocaleString('en-GB', {minimumFractionDigits: 2})
}
export function twoDigitNoTrailingZero ( num: number ) {
    return (num / 100) + ''
}

export function hash(string: string) {
    var hash = 0, i, chr;
    if (string.length === 0) return hash;
    for (i = 0; i < string.length; i++) {
      chr   = string.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash;
};