import React, { useState, useEffect } from 'react'
import {
	Table,
	TrHead,
	Tr,
	Th,
	Td,
	TableControls,
	TableControlItem,
	ControlLabel,
	BtnStatus,
} from "../table.style";
import { suppliers, ISupplier, enumOrderLineType } from "../shared";
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import { TdInputTxt, Section, InnerSection, ModalWrapper, PrevEntryBtn, NextEntryBtn } from './index.style'
import { twoDigitString, twoDigitNoTrailingZero } from '../../helpers'
import { addThRow, addTdRow, calculateVatAndTotals, IOrderItem, IOrderItemWithVat, IBookeepingApiMsg, IBookeepingApiMsgItem, IBookeepingApiMsgIPurchase, sendBookkeepingData } from './shared'
import Database from '../../firestore';

export default function BookkeepingSupplier( props: IBookkeepingSupplierEntry ) {
    const {
        supplier,
        bilagsnummer,
        db,
        cbNextPrevEntry
    } = props

    const applyDkVat = supplier.dkVat === true
    
    const [ invoiceDate, setInvoiceDate ] = useState<Date | undefined>( undefined )
    const [ invoiceNumber, setInvoiceNumber ] = useState<string>( "" )
    const [ paymentDate, setPaymentDate ] = useState<Date | undefined>( undefined )
    const [ paymentTotalAmount, setPaymentTotalAmount ] = useState<number>( 0 )
    const [ paymentVatAmount, setPaymentVatAmount ] = useState<number>( 0 )
    const [ vatRoundingAmount, setVatRoundingAmount ] = useState<number>( 0 )
    const [ orderItemsWithVat, setOrderItemsWithVat ] = useState<IOrderItemWithVat[]>( props.orderItems.map( x => ({
        ...x,
        vat: applyDkVat === true ? Math.round( x.amount / 4) : 0
    })) )

    

    const handleInvoiceDate = (date: Date) => {
        setInvoiceDate( date )
        if(paymentDate == null) {
            setPaymentDate(date)
        }
    };
    const handlePaymentDate = (date: Date) => {
        setPaymentDate( date )
    };
    const updatePaymentTotalAmount = ( event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentTotalAmount( Math.round(parseFloat(event.target.value) * 100) ) 
    }
    const updatePaymentVatAmount = ( event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentVatAmount( Math.round(parseFloat(event.target.value) * 100) ) 
    }
    const updateInvoiceNumber = ( event: React.ChangeEvent<HTMLInputElement>) => {
        setInvoiceNumber( event.target.value ) 
    }
    
    const {
        totalAmountExVat,
        totalVatFromTotal,
        totalVatSumItems,
    } = calculateVatAndTotals( orderItemsWithVat, applyDkVat )
    
    useEffect( () => {
        const vatToAdd = totalVatFromTotal - totalVatSumItems
        if( vatToAdd !== 0) setOrderItemsWithVat(
            [...orderItemsWithVat, {
            skuInternal: "dk-vat-rounding",
            name: "Afrunding Moms",
            amount: vatToAdd,
            qty: 1,
            vat:  0,
            type: enumOrderLineType.dkVatRounding
        }] )
    }, [])

    const bookkeepingDataItems: IBookeepingApiMsgItem[] = []
    const orderItemData = orderItemsWithVat.map( (item, i) => {

        const invoiceLine = ( () => {
            switch( item.type ) {
                case enumOrderLineType.product:
                    return `Varekøb - ${item.qty}x - ${item.skuInternal}`
                case enumOrderLineType.discount:
                    return 'Varekøb - Rabat'
                case enumOrderLineType.shipping:
                    return 'Varekøb - Forsendelse'
                case enumOrderLineType.shippingExtras:
                    return 'Varekøb - Eksta forsendelsesgebyr'
                case enumOrderLineType.dkVatRounding:
                    return 'Varekøb - Afrunding'
            }
            return "Unknown"
        } )();

        bookkeepingDataItems.push({
            text: invoiceLine,
            amount: item.amount,
            qty: item.qty,
            type: item.type
        })

        return <Tr>
            <Td>{item.name}</Td>
            <Td>{invoiceLine}</Td>
            <Td>{item.type}</Td>
            <Td>{item.qty}</Td>
            <Td rightAlign={true}>{twoDigitString(item.amount)}</Td>
            <Td rightAlign={true}>{twoDigitString(item.vat)}</Td>
        </Tr>
    })

    const readyToBookkeep = ( () => {
        const emptyInvDate = invoiceDate === undefined
        const emptyPayDate = paymentDate === undefined
        const emptyInvNo = invoiceNumber === ""
        // const emptyPayAmt = paymentTotalAmount === 0
        const criteria = [emptyInvDate, emptyPayDate, emptyInvNo]
        return !criteria.some( x => x)
    })()

    const bookkeepingData: IBookeepingApiMsg = {
        isSale: false,
        purchaseSpecific: {
            supplierId: supplier.id,
            supplierVoucherId: invoiceNumber,
            paymentDate: paymentDate ? toDateString(paymentDate) : '',
        },
        internalVoucherId: bilagsnummer,
        invoiceDate: invoiceDate ? toDateString(invoiceDate) : '',
        items: bookkeepingDataItems
    }
    return (
        <ModalWrapper>
            <PrevEntryBtn onClick={ () => { cbNextPrevEntry(true) }}>Previous</PrevEntryBtn>
            <NextEntryBtn onClick={ () => { cbNextPrevEntry(false) }}>Next</NextEntryBtn>
            <Section>
                <InnerSection>
                    <Table>
                        <thead>
                        </thead>
                        <tbody>
                            { addTdRow(["Regnskabsår", "2023/2024"], [])}
                            { supplier ? addTdRow(["Leverandør", supplier.name], []): ""}
                            { supplier ? addTdRow(["Momstype", supplier.dkVat === true ? "DK Moms" : "EU Moms"], []): ""}
                            { addTdRow(["Bilagsnummer (intern)", bilagsnummer], [])}
                        </tbody>
                    </Table>
                </InnerSection>
                <InnerSection>
                    <Table>
                        <thead>
                        </thead>
                        <tbody>
                            { addTdRow(["Fakturanummer (leverandør)", <TdInputTxt type="text" value={invoiceNumber} onChange={updateInvoiceNumber} />], [])}
                            { addTdRow(["Fakturadato", <DatePicker selected={invoiceDate} onChange={handleInvoiceDate} dateFormat="yyyy-MM-dd" />], [])}
                            { addTdRow(["Betalingsdato", <DatePicker selected={paymentDate} onChange={handlePaymentDate} dateFormat="yyyy-MM-dd" />], [])}
                            {/* { addTdRow(["Total betalingsbeløb", <TdInputTxt type="number" value={twoDigitNoTrailingZero(paymentTotalAmount)} step="0.01" onChange={updatePaymentTotalAmount} />], [])} */}
                            {/* { applyDkVat ? addTdRow(["Total momsbeløb", <TdInputTxt type="number" value={twoDigitNoTrailingZero(paymentVatAmount)} step="0.01" onChange={updatePaymentVatAmount} />], []) : ""} */}
                        </tbody>
                    </Table>
                </InnerSection>
            </Section>
            <Section>
                <Table>
                        <thead>
                            {addThRow(["Product Name","Invoice Line","Type","Qty","Amount","Vat"], [])}
                        </thead>
                        <tbody>
                            {orderItemData}
                            {addTdRow(["TOTAL (SUM)","","","",twoDigitString(totalAmountExVat),twoDigitString(totalVatSumItems)], [4, 5])}
                            {addTdRow(["TOTAL (SIMULATION)","","","",twoDigitString(totalAmountExVat),twoDigitString(totalVatFromTotal)], [4, 5])}
                            {addTdRow(["TOTAL (INDTASTET)","","","",twoDigitString(paymentTotalAmount - paymentVatAmount),twoDigitString(paymentVatAmount)], [4, 5])}
                        </tbody>
                </Table>
            </Section>
            <Section>
                <button onClick={ () => { if(readyToBookkeep) sendBookkeepingData(bookkeepingData, () => { db.updateSupplierPurchaseBookkeepingData( bilagsnummer, bookkeepingData ) }) }} disabled={!readyToBookkeep}>BOGFØR!</button>
            </Section>
        </ModalWrapper>
    )
}

export interface IBookkeepingSupplierEntry {
    supplier: ISupplier,
    bilagsnummer: number,
    db: Database,
    orderItems: IOrderItem[],
    cbNextPrevEntry: (isPrev: boolean) => void
}

function toDateString(dt: Date) {
    const offset = -120
    // const offset = (new Date()).getTimezoneOffset()
    dt = new Date(dt.getTime() - offset * 60000);
    return dt.toISOString().substr(0,10);
  }