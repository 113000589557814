import { IOrderP2b } from './admin-api.models'
export type IOrder = IOrderP2b

const domain = "https://europe-west1-p2bike-internal.cloudfunctions.net/adminApi"
// const domain = "http://localhost:8080"

export async function getOrders(): Promise<IOrderP2b[]> {
    const urlString: string[] = [
        //`type=${type}`,
    ]

    let dataOutput: IOrderP2b[] = []
    await new Promise<void>( res => {

        fetch(domain + `?` + urlString.join('&'), {
            method: 'GET', // or 'PUT'
            // mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })  
        .then((response) => response.json())
        .then((data) => {
            console.log('Success:', data); 
            dataOutput = data
            res()
        })
        .catch((error) => {
            console.error('Error:', error);
            res()
        });
    })
    console.log({dataOutput})
    return dataOutput
}