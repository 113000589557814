import React, { useEffect, useState } from "react";
import { Table, Tr, TrHead, Th, Td } from "../table.style";
import {
	ChangeTable,
	ChangeTableTd,
	ChangeTableTh,
	ComponentWrapper,
	Link,
} from "./index.style";
import { IPurchaseOrder } from "../../App";
import Database, { IReservedProducts } from "../../firestore";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { getOrders, IOrder } from "./../../api";
import { IOrderP2b } from "../../api/admin-api.models";

type StockItemChange = {
	purchaseId: number;
	sku: string;
	date: string;
	description: string;
	qtyChange: number;
	link: string;
};

type StockItems = {
	sku: string;
	name: string;
	qty: number;
	changes: StockItemChange[];
};

const BANNED_PRODUCTS = ["purchase-ship", "purchase-ship-extra"];

function parsePurchaseOrders(supplierPurchaseOrders: IPurchaseOrder[]) {
	const stockItemChanges: StockItemChange[] = [];
	supplierPurchaseOrders.forEach((po) =>
		po.data
			.filter(
				(orderItem) =>
					!BANNED_PRODUCTS.some(
						(banned) => banned === orderItem.internalSku
					)
			)
			.forEach((orderItem) => {
				const { internalSku, qty, productName, purchaseId } = orderItem;

				const dateToUse = [
					po.bookkeepingData?.invoiceDate,
					new Date(orderItem.insertTime * 1e3)
						.toISOString()
						.substring(0, 10),
				];

				const date =
					dateToUse
						.filter((x) => x !== undefined)
						.sort((a, b) => ((a ?? "") > (b ?? "") ? 1 : -1))[0] ??
					"";

				stockItemChanges.push({
					purchaseId,
					date,
					sku: internalSku,
					description: `Purchased in ${purchaseId}`,
					qtyChange: qty,
					link: "about:blank",
				});
			})
	);
	return stockItemChanges;
}

function parseWooOrders(
	wooOrders: IOrderP2b[],
	reservedProducts: IReservedProducts[]
) {
	const stockItemChanges: StockItemChange[] = [];
	wooOrders.forEach((wooOrder) => {
		const order = reservedProducts.find(
			(res) => res.shopInvoiceId === Number(wooOrder.invoiceNumber)
		);
		if (order === undefined) return;

		order.items.forEach((item) => {
			stockItemChanges.push({
				purchaseId: item.supplierPurchaseId,
				date: wooOrder.invoiceDate ?? "",
				sku: item.sku,
				description: `Sold in o${wooOrder.orderNumber}/i${
					wooOrder.invoiceNumber ?? ""
				}`,
				qtyChange: -1,
				link: "about:blank",
			});
		});
	});
	return stockItemChanges;
}

export default function StockComponent(props: {
	isSignedIn: boolean;
	supplierPurchaseOrders: IPurchaseOrder[];
	reservedProducts: IReservedProducts[];
}) {
	const { isSignedIn, supplierPurchaseOrders, reservedProducts } = props;

	const [wooOrders, setWooOrders] = useState<IOrderP2b[]>([]);
	const [stockItems, setStockItems] = useState<StockItems[]>([]);
	useEffect(() => {
		if (wooOrders.length === 0 && isSignedIn) {
			getOrders().then((wooOrders) => {
				setWooOrders(wooOrders);
			});
		}
	}, [isSignedIn]);

	useEffect(() => {
		let allStockItemChanges: StockItemChange[] = [];
		allStockItemChanges = [
			...allStockItemChanges,
			...parsePurchaseOrders(supplierPurchaseOrders),
		];
		allStockItemChanges = [
			...allStockItemChanges,
			...parseWooOrders(wooOrders, reservedProducts),
		];

		const allSkus = [...new Set(allStockItemChanges.map((x) => x.sku))];

		setStockItems(
			allSkus.map((sku) => {
				const changes = allStockItemChanges.filter(
					(item) => item.sku === sku
				);
				return {
					sku,
					name: changes[0].description,
					qty: changes.reduce((acc, item) => acc + item.qtyChange, 0),
					changes,
				};
			})
		);
	}, [supplierPurchaseOrders, wooOrders, reservedProducts]);

	const [expandedSku, setExpandedSku] = useState<string | undefined>(
		undefined
	);

	const elemStockItems = stockItems.map((item) => {
		let accChange = 0;
		const changes = item.changes
			.sort((a, b) => (a.date > b.date ? 1 : -1))
			.map((change) => {
				const { date, description, qtyChange, link, purchaseId } =
					change;
				accChange += qtyChange;
				return (
					<tr>
						<ChangeTableTd>{date}</ChangeTableTd>
						<ChangeTableTd alignRight={false}>
							{description}
						</ChangeTableTd>
						<ChangeTableTd alignRight={true}>
							{qtyChange}
						</ChangeTableTd>
						<ChangeTableTd alignRight={true}>
							{accChange}
						</ChangeTableTd>
						<ChangeTableTd>
							<Link target="_blank" href={link}>
								Link
							</Link>
						</ChangeTableTd>
					</tr>
				);
			});

		const isExpanded = expandedSku === item.sku;
		return (
			<>
				<Tr hidden={false}>
					<Td
						clickable={true}
						onClick={() =>
							setExpandedSku(!isExpanded ? item.sku : undefined)
						}
					>
						{isExpanded ? (
							<IoMdArrowDropup size={24} />
						) : (
							<IoMdArrowDropdown size={24} />
						)}
					</Td>
					<Td>{item.name}</Td>
					<Td>{item.sku}</Td>
					<Td>{item.qty}</Td>
				</Tr>
				<Tr hidden={!isExpanded}>
					<Td colSpan={3}>
						<ChangeTable>
							<thead>
								<ChangeTableTh>Date</ChangeTableTh>
								<ChangeTableTh alignRight={false}>
									Description
								</ChangeTableTh>
								<ChangeTableTh alignRight={true}>
									Qty
								</ChangeTableTh>
								<ChangeTableTh alignRight={true}>
									Acc Qty
								</ChangeTableTh>
								<ChangeTableTh>Link</ChangeTableTh>
							</thead>
							<tbody>{changes}</tbody>
						</ChangeTable>
					</Td>
				</Tr>
			</>
		);
	});

	return (
		<ComponentWrapper hide={!isSignedIn}>
			<h1>Stock</h1>
			<Table>
				<thead>
					<Tr>
						<Th>&nbsp;</Th>
						<Th>Varenavn</Th>
						<Th>Sku</Th>
						<Th>Stock Status</Th>
					</Tr>
				</thead>
				<tbody>{elemStockItems}</tbody>
			</Table>
		</ComponentWrapper>
	);
}
