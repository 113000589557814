import React, { useState } from 'react'
import CloseIcon from './close.svg'
import {
    ModalContainer,
    ScreenOverlay,
    ModalWrapper,
    CloseModal,
    Content
} from './index.style'

export default function Modal(props: { mobileView: boolean, onClose: () => void, children: any, height: number, width: number }) {
    const { children, height, width, mobileView } = props
    //const [isClosed, close] = useState(false);
    //if(isClosed) return (null);
    return (
        <ModalContainer>
            <ScreenOverlay onClick={() => { props.onClose(); }} />
            <ModalWrapper mobileView={mobileView} height={height} width={width}>
                <CloseModal src={CloseIcon} onClick={() => { props.onClose(); }} />
                {/*<CloseModal src={CloseIcon} onClick={() => { close(true) }} />*/}
                <Content>{children}</Content>
            </ModalWrapper>
        </ModalContainer>
    )
}
