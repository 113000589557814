
export const missingSkus: FixEntry[] = [

    
    // { sku: "", invoiceId: "", text: "" },
    // { sku: "", invoiceId: "", text: "" },
    // { sku: "", invoiceId: "", text: "" },
    // { sku: "", invoiceId: "", text: "" },
    // { sku: "", invoiceId: "", text: "" },
    // { sku: "", invoiceId: "", text: "" },
    // // { sku: "", invoiceId: "", text: "" },
    // { sku: "custom-brinckers-c300-display", invoiceId: "22950", text: "Brinckers Display C300S 36V UART" },
    // { sku: "26dc-174-black", invoiceId: "22916", text: "Promovec Carrier 6/2 XL 36V - Black, 17.4 Ah", force: true },
    // { sku: "rainlegs-black-l", invoiceId: "22911", text: "Cykel regnbukser / benbeskytter - Sort", force: true },
    // { sku: "rainlegs-reflective-m", invoiceId: "22910", text: "Cykel regnbukser / benbeskytter - Reflektiv", force: true },
    // { sku: "custom-carrier62-xl-156-36v", invoiceId: "22901", text: "Promovec Carrier 6/2 XL 36V - Black, 15.6 Ah", force: true },
    // { sku: "custom-promovec-key", invoiceId: "22897", text: "Nøgler (rå) til Promovec batteri" },
    // { sku: "custom-promovec-lock-unit", invoiceId: "22882", text: "Promovec Carrier 5 låseenhed inkl. nøgler" },
    // { sku: "custom-brinckers-c300-display", invoiceId: "22856", text: "Brinckers Display 300 36V UART" },
    // { sku: "custom-brinckers-black-fork", invoiceId: "22852", text: "Brinckers Granville Forgaffel" },
    // { sku: "custom-hartje-bosch-display", invoiceId: "22844", text: "Bosch Display" },
    // { sku: "custom-brinckers-batterylock", invoiceId: "22791", text: "Låsecylinder og nøgler til Keola-Brinckers batteri" },
    // { sku: "cover-bl0302", invoiceId: "22697", text: "TranzX BL03 dæksel til ladestik - Grå" },
    // { sku: "4762-128-black", invoiceId: "22647", text: "Promovec Downtube2 36V 12.8Ah" },
    // { sku: "custom-key", invoiceId: "22773", text: "Låsecylinder og nøgler til Keola-Brinckers batteri" },
    // { sku: "special-yamaha-battery", invoiceId: "22824", text: "Yamaha kompatibelt bagagebærerbatteri (lang) 36V 14.5Ah" },
    // { sku: "custom-key", invoiceId: "22791", text: "Promovec Nøgler (rå)" },
    // { sku: "custom-62-36v-174ah", invoiceId: "22769", text: "Promovec Carrier 6/2 XL 36V - Black, 17.4 Ah", force: true },
    // { sku: "7t34-167-black", invoiceId: "22762", text: "TranzX BL15 - Black, 13 Ah", force: true },
    // { sku: "custom-ABD030C001540", invoiceId: "22756", text: "TranzX DP03 display 24V" },
    // { sku: "21qx-11-black", invoiceId: "22710", text: "TranzX BL02 24V 11Ah" },
    // { sku: "custom-brinckers-display-40101", invoiceId: "22690", text: "Brinckers Display 300 36V UART" },
    // { sku: "custom-50010-XL", invoiceId: "22680", text: "Promovec Bagagebærer til Carrier 6/2 XL 28''" },
    // { sku: "custom-key", invoiceId: "22652", text: "Promovec Batterinøgler" },
    // { sku: "21qx-11-black", invoiceId: "22631", text: "Kildemoes - BL02 batteri 24V 11Ah inkl. oplader" },
    // { sku: "b3t5-14-black", invoiceId: "22622", text: "Bafang BT C01 14Ah" },
    // { sku: "special-yamaha-battery", invoiceId: "22632", text: "Yamaha kompatiblet bagagebærerbatteri (lang) 36V 14.5Ah" },
    // { sku: "21qx-11-black", invoiceId: "22601", text: "TranzX BL02 24V - Gray, 11Ah" },
    // { sku: "charger-keola-brinckers-36v-18a-coax", invoiceId: "22592", text: "Keola-Brinckers oplader - enkelt stik - 36V 1,8A" },
    // { sku: "b3t5-14-black", invoiceId: "22580", text: "Bafang BT C01 14ah" },
    // { sku: "promovec-lock", invoiceId: "22527", text: "Promovec tændingslås og nøgler til Carrier 6/2" },
    // { sku: "panasonic-510w-25v", invoiceId: "22500", text: "Ebike-Vision PowerPack 26V 20Ah" },
    // { sku: "charger-panasonic-26v-6a", invoiceId: "22500", text: "Hurtiglader til Ebike-Vision PowerPack 26V" },
    // { sku: "keys-promovec", invoiceId: "22501", text: "Nøgler til Promovec batteri" },
    // { sku: "438a-xl-174-black", invoiceId: "2600", text: "Promovec Carrier 6/2 XL 17.4 Ah" },
    // { sku: "custom-sco-led-display", invoiceId: "2592", text: "Promovec LED dispay til Puch og SCO" },
    // { sku: "438a-xl-174-black", invoiceId: "2590", text: "Promovec Carrier 6/2 XL 36V 17.4 Ah" },
    // { sku: "3vb9_400-11-black ", invoiceId: "2571", text: "Bosch PowerPack 400 Classic Frame - Black" },
    // { sku: "Custom-2541", invoiceId: "2541", text: "TranzX Controller CN02LE, 24V for CA-03" },
    // { sku: "charger-tranzx-24v-2a-4pin", invoiceId: "2525", text: "TranzX oplader 24V - 4 pin" },
    // { sku: "charger-bafang-43v-2a-coax", invoiceId: "2516", text: "Bafang Batteri Lader 43V 2A" },
    // { sku: "438a-88-black", invoiceId: "2512", text: "Promovec Carrier 6/2 36V - Black, 8.8 Ah" },
    // { sku: "45de-66-black", invoiceId: "2511", text: "Promovec Carrier 3 36V - Black, 6.6 Ah" },
    // { sku: "438a-88-black", invoiceId: "2510", text: "Promovec Carrier 6/2 36V - Black, 8.8 Ah" },
    // { sku: "abeb-9-black", invoiceId: "2509", text: "Ansmann bagagebærer batteri - 36V - Black, 9 Ah" },
    // { sku: "438a-88-black", invoiceId: "2508", text: "Promovec Carrier 6/2 36V - Black, 8.8 Ah" },
    // { sku: "438a-104-black", invoiceId: "2507", text: "Promovec Carrier 6/2 36V - Black, 10.4 Ah" },
    // { sku: "charger-promovec-36v-2a-3pin", invoiceId: "2506", text: "Oplader Promovec Carrier 2, 36V, 3 ben" },
    // { sku: "8b2d-88-black", invoiceId: "2505", text: "Promovec Carrier 5 36V - Black, 8.8 Ah" },
    // { sku: "438a-88-black", invoiceId: "2504", text: "Promovec Carrier 6/2 36V - Black, 8.8 Ah" },
    // { sku: "45de-66-black", invoiceId: "2503", text: "Promovec Carrier 3 36V - Black, 6.6 Ah" },
    // { sku: "438a-104-black", invoiceId: "2502", text: "Promovec Carrier 6/2 36V - Black, 10.4 Ah" },
    // { sku: "438a-88-black", invoiceId: "2501", text: "Promovec Carrier 6/2 36V - Black, 8.8 Ah" },
    // { sku: "cov-24687", invoiceId: "2500", text: "Battericover til bagagebærer batteri" },
    // { sku: "cov-24688", invoiceId: "2500", text: "Battericover til batteri på underrøret" },
]

export const replaceEntries: {
    from: FixEntryFrom,
    to: FixEntryTo,
}[] = [{
    from: { sku: "45de-66-black", invoiceId: "2562" },
    to: { sku: "45de-96-black" },
},{
    from: { sku: "kb9a-104-black", invoiceId: "22583" },
    to: { sku: "kb9a-13-black" },
},{
    from: { sku: "kb9a-104-black", invoiceId: "22584" },
    to: { sku: "kb9a-13-black" },
},{
    from: { sku: "kb9a-104-black", invoiceId: "22585" },
    to: { sku: "kb9a-13-black" },
},{
    from: { sku: "kb9a-104-black", invoiceId: "22586" },
    to: { sku: "kb9a-13-black" },
}]

type FixEntry = { sku: string, invoiceId: string, text: string, force?: boolean }
type FixEntryFrom = { sku: string, invoiceId: string }
type FixEntryTo = { sku: string }