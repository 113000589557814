import styled from 'styled-components'


export const TdInputTxt = styled.input`
    width: 100%;
    height: 20px;
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }   
`;
export const TdInputSelect = styled.select`
    width: 100%;
    height: 20px;
`
export const TdInputOption = styled.option`
`