import React from 'react'
import { Table, Tr, TrHead, Th, Td } from '../table.style'
import { ComponentWrapper, Link } from './index.style'

export default function StartTaskComponent( props: { isSignedIn: boolean }) {
    const { isSignedIn } = props;
    return (
        <ComponentWrapper hide={!isSignedIn}>
            <h1>Start Task</h1>
            <Table>
                <thead>
                    <Tr>
                        <Th>Task</Th>
                        <Th>Description</Th>
                    </Tr>
                </thead>
                <tbody>
                    <Tr>
                        <Td><Link href='https://scrape-bikepartsde-6zzkoa3sgq-ew.a.run.app' target='_blank'>Scrape Bikeparts</Link></Td>
                        <Td></Td>
                    </Tr>
                    <Tr>
                        <Td><Link href='https://scrape-hartje-6zzkoa3sgq-ew.a.run.app' target='_blank'>Scrape Hartje</Link></Td>
                        <Td></Td>
                    </Tr>
                    <Tr>
                        <Td><Link href='https://scrape-promovec-6zzkoa3sgq-ew.a.run.app' target='_blank'>Scrape Promovec</Link></Td>
                        <Td></Td>
                    </Tr>
                    <Tr>
                        <Td><Link href='https://scrape-shimano-6zzkoa3sgq-ew.a.run.app' target='_blank'>Scrape Shimano</Link></Td>
                        <Td></Td>
                    </Tr>
                    <Tr>
                        <Td><Link href='https://europe-west1-p2bike-internal.cloudfunctions.net/compileWooBatteryRanges' target='_blank'>Compile Battery Ranges</Link></Td>
                        <Td>Opdaterer produktdetaljesider med battery ranges og leveringstid (Skal helst køres efter Woocommerce Data Import)</Td>
                    </Tr>
                    <Tr>
                        <Td><Link href='https://europe-west1-p2bike-internal.cloudfunctions.net/updateBatteryFinder' target='_blank'>Update Battery Finder</Link></Td>
                        <Td>Opdaterer batterifinderen (Skal helst køres efter Woocommerce Data Import)</Td>
                    </Tr>
                    <Tr>
                        <Td><Link href='https://europe-west1-p2bike-internal.cloudfunctions.net/imageUploader' target='_blank'>Update Battery Images</Link></Td>
                        <Td>Uploader batteribilleder i <a href="https://drive.google.com/drive/folders/1PPU-klQNnGvuXv3qKpDiXlh47zEWeOA6" target="_blank">denne mappe</a> til WooCommerce</Td>
                    </Tr>
                    <Tr>
                        <Td><Link href='https://europe-west1-p2bike-internal.cloudfunctions.net/wooDataImport' target='_blank'>Woocommerce Data Import</Link></Td>
                        <Td>For at opdatere WooCommerce gør følgende:
                            <ol>
                                <li>Kopier linket</li>
                                <li>Login på Power2bike.com -&gt; Admin</li>
                                <li>Tryk WooCommerce -&gt; Product Im-Ex</li>
                                <li>Klik import i toppen</li>
                                <li>Under Method 3, indsæt linket</li>
                                <li>Afkryds "Update products if exists"</li>
                                <li>Klip "Upload file and import"</li>
                                <li>Fortsæt gennem de næste trin</li>
                            </ol>
                        </Td>
                    </Tr>
                </tbody>
            </Table>
        </ComponentWrapper>
    )
}
