import React, { useState } from 'react'
import { IUnreservedProduct } from '../../App';
import Database from '../../firestore';

type IRewriteProduct = {
    invoiceNumber: string;
    itemName: string;
    unreservedProducts: IUnreservedProduct[],
    db: Database,
}

export default function RewriteProduct(props: IRewriteProduct) {
    const {
        invoiceNumber,
        itemName,
        unreservedProducts,
        db,
    } = props;

    const [targetSku, setTargetSku] = useState<string | undefined>(undefined);
    const [showManual, setShowManual] = useState<boolean>(false);
  const customUnreservedProducts = unreservedProducts.filter(x=> x.sku.indexOf('custom-') > -1);
  const options = customUnreservedProducts.map(x => <option value={x.sku}>{x.sku}</option>);

  const optionClicked = (value: string) => {
    if(value === "type-manually") {
        setShowManual(true)
        return;
    };
    setTargetSku(value);
  }

  const updateManualInput = (value: string) => {
    setTargetSku(value);
  }

  const submit = () => {
    db.setOverwriteInvoiceProduct({
        invoiceNumber,
        itemName,
        newSku: targetSku ?? '',
      })
  }

  return (
    <div>
        {showManual ? <input onChange={(event) => updateManualInput(event.target.value)} type="text" /> : <select onChange={(event) => optionClicked(event.target.value)}>
            <option value="type-manually">-- Type input --</option>
            {options}
        </select>
        }
        <br />
        {targetSku ? <button onClick={submit}>Submit</button> : '' }
    </div>
  )
}
