import React, { useState, useEffect } from 'react'
import { Table, TrHead, Tr, Th, Td, TableControls, TableControlItem, ControlLabel, BtnStatus } from '../table.style'
import { TdInputTxt, TdInputSelect, TdInputOption } from './index.style'
import Database, { ISupplierPurchaseLine, EnumOrderProductStatus, EnumStatus, IReservedProducts } from '../../firestore'
import SearchSuggestions from './search-suggestions'
import { purchaseAddRow, purchaseDeleteRow, purchaseSelectSuggestion } from './index.purchase-controller'
import EditRows from './edit-rows'
import { unixToDateTimeString, twoDigitString, twoDigitNoTrailingZero, unixToDateTimeStringTwoLines } from './../../helpers'
import { suppliers, ISupplier, enumOrderLineType } from '../shared'
import { IPurchaseOrder } from './../../App'
import { BookkeepingSupplier, enumBookkeepingType } from '../bookkeeping-component'
import Modal from '../utilities/modal'
import { IBookeepingApiMsg } from '../bookkeeping-component/shared'

export default function SupplierComponent( props: { db: Database, isSignedIn: boolean, supplierPurchaseOrders: IPurchaseOrder[], supplierPurchaseOrderSuggestions: IPurchaseOrder[], reservedProducts: IReservedProducts[] } ) {
    const { db, isSignedIn, supplierPurchaseOrders, supplierPurchaseOrderSuggestions, reservedProducts } = props
    let highestPurchaseId = Math.max( ...supplierPurchaseOrders.map( x => x.id ) )
    highestPurchaseId = ( Number.isFinite(highestPurchaseId) && !Number.isNaN(highestPurchaseId) ) ? highestPurchaseId : 58999 - 2

    // Edit Rows
    const [purchaseBeingEdited, setPurchaseBeingEdited] = useState<number | undefined>(undefined);
    const [editRows, setEditRows] = useState<ISupplierPurchaseLine[]>([])
    const [modal, setModal] = useState<JSX.Element | undefined>(undefined)
    const [hideRowsWithNoneInStock, setHideRowsWithNoneInStock] = useState<boolean>(false);
    const addNewPurchase = () => {
        const empty: ISupplierPurchaseLine[] = []
        setEditRows(purchaseAddRow( { purchaseArr: empty, purchaseId: highestPurchaseId + 1 } ))
        setPurchaseBeingEdited(highestPurchaseId + 1)
    }

    const addNewRow = (purchaseId: number, shippingType?: enumOrderLineType) => {
        const initStatus = (editRows.length > 0) ? editRows[0].status : undefined
        console.log({purchaseId})
        setEditRows(purchaseAddRow( { purchaseArr: editRows, purchaseId, initStatus, shippingType } ))
    }

    const setSugBoxEditRowLinkIdxFnc = (idx: number) => {
        setSugBoxEditRowLinkIdx(idx)
    }
    
    
    // Suggestion
    const [sugBoxEditRowLinkIdx, setSugBoxEditRowLinkIdx] = useState<number>(-1);
    

    const [showProdSearch, setShowProdSearch] = useState<boolean>(false);
    const [prodSupplierFilterInput, setProdSupplierFilterInput] = useState<IFilterInput>({supplierId: '-1', internalSku: '',supplierSku: '', productName:''});
    const prodSupplierArrayFiltered = filterProdSupplierArray( supplierPurchaseOrderSuggestions, prodSupplierFilterInput )
//asd
    const purchaseOrderActions = {
        edit: ( purchaseId: number ) => {
            const lines = supplierPurchaseOrders.find( x => x.id === purchaseId )
            if( lines === undefined ) return;
            setEditRows(lines.data)
            setPurchaseBeingEdited(purchaseId)
        },
        delete: ( purchaseId: number ) => {
            db.deleteSupplierPurchase(purchaseId).then( () => {
                setEditRows([])
            })
        },
        save: () => {
            db.addSupplierPurchase(editRows).then( () => {
                setEditRows([])
            })
        },
        cancel: () => {
            setEditRows([])
        },
    };

    const doBookkeeping = ( purchaseId: number ) => {
        const nextPrevBookkeeping = ( prev: boolean ) => {
            setModal(undefined)
            if(prev) {
                setTimeout( () => {doBookkeeping( purchaseId - 1 )} , 100) 
            } else {
                setTimeout( () => {doBookkeeping( purchaseId + 1 )} , 100) 
            }
        }
    
        const target = supplierPurchaseOrders.find( x => x.id === purchaseId)
        if( target === undefined ) return;
        const data = target.data
        const supplier = suppliers.find( s => s.id === data[0].supplierId )
        if(supplier === undefined) return;
        
        const skuToType = ( inputSku: string ) => {
            switch( inputSku ) {
                case enumOrderLineType.shipping:
                    return enumOrderLineType.shipping;
                case enumOrderLineType.shippingExtras:
                    return enumOrderLineType.shippingExtras;
                case enumOrderLineType.dkVatRounding:
                    return enumOrderLineType.dkVatRounding;
                case enumOrderLineType.discount:
                    return enumOrderLineType.discount;
            }
            return enumOrderLineType.product

        }
        const orderItems = editRows.map( item => ({
            skuInternal: item.internalSku,
            name: item.productName,
            amount: item.price,
            qty: item.qty,
            type: skuToType( item.internalSku )
        }))
        setModal(
            <Modal mobileView={false} onClose={ () => { setModal(undefined) }} height={800} width={1000}>
                <BookkeepingSupplier 
                    db={db}
                    supplier={supplier}
                    bilagsnummer={purchaseId}
                    orderItems={orderItems}
                    cbNextPrevEntry={nextPrevBookkeeping}
                />
            </Modal>
        )
    }
    const deleteEditRow = (idx: number) => {
        setEditRows(purchaseDeleteRow({
            editRowsArr: editRows,
            deleteIdx: idx
        }))
    }
    // const handlerProdSupplierFilter = ( event: React.ChangeEvent<HTMLInputElement|HTMLSelectElement> ) => {
    //     const placeholder = event.target.getAttribute('placeholder') || event.target.name || 'unknown'
    //     const val = event.target.value.toLowerCase()
    //     const updateData = { ...prodSupplierFilterInput, [placeholder]: val }
    //     setProdSupplierFilterInput(updateData)
    // }
    const handlerFieldChange = (event: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        const editRowIdx: number = parseInt(event.target.getAttribute('data-edit-row-idx') + '')
        const fieldName: string = event.target.getAttribute('data-field-name') + ''
        const val: string = event.target.value

        if(fieldName === "supplierId") {
            console.log({editRows, editRowIdx,val})
            setEditRows(setAttr(editRows, editRowIdx, 'supplierId', enumDataType.int, val))
            const updateData = { ...prodSupplierFilterInput, [fieldName]: val.toLowerCase() }
            // setSugBoxEditRowLinkIdx(editRowIdx)
            setProdSupplierFilterInput(updateData)
        }
        if(fieldName === "internalSku") {
            setEditRows(setAttr(editRows, editRowIdx, 'internalSku', enumDataType.string, val))
            const updateData = { ...prodSupplierFilterInput, [fieldName]: val.toLowerCase() }
            // setSugBoxEditRowLinkIdx(editRowIdx)
            setProdSupplierFilterInput(updateData)
        }
        if(fieldName === "supplierSku") {
            setEditRows(setAttr(editRows, editRowIdx, 'supplierSku', enumDataType.string, val))
            const updateData = { ...prodSupplierFilterInput, [fieldName]: val.toLowerCase() }
            // setSugBoxEditRowLinkIdx(editRowIdx)
            setProdSupplierFilterInput(updateData)
        }
        if(fieldName === "productName") {
            setEditRows(setAttr(editRows, editRowIdx, 'productName', enumDataType.string, val))
            const updateData = { ...prodSupplierFilterInput, [fieldName]: val.toLowerCase() }
            // setSugBoxEditRowLinkIdx(editRowIdx)
            setProdSupplierFilterInput(updateData)
        }
        if(fieldName === "qty") {
            setEditRows(setAttr(editRows, editRowIdx, 'qty', enumDataType.int, val))
        }
        if(fieldName === "price") {
            setEditRows(setAttr(editRows, editRowIdx, 'price', enumDataType.int100, val))
            const updateData = { ...prodSupplierFilterInput, [fieldName]: val.toLowerCase() }
            // setSugBoxEditRowLinkIdx(editRowIdx)
            setProdSupplierFilterInput(updateData)
        }
        if(fieldName === "status") {
            // Sync all statuses
            let editRowsNew: ISupplierPurchaseLine[] = [...editRows]
            editRows.forEach( (x, i) => {
                editRowsNew = [...setAttr(editRowsNew, i, 'status', enumDataType.string, val)]
            })
            setEditRows(editRowsNew)
            const updateData = { ...prodSupplierFilterInput, [fieldName]: val.toLowerCase() }
            // setSugBoxEditRowLinkIdx(editRowIdx)
            setProdSupplierFilterInput(updateData)
        }
    }

    const cbSelectProduct: (selectedItem: IProdSupplier) => void = ( selectedItem ) => {
        const newRows = purchaseSelectSuggestion({
            activeEditRowIndex: sugBoxEditRowLinkIdx,
            editRowsArr: editRows,
            selectedItem,
        })
        if(newRows === undefined) return;
        setEditRows([...newRows])
    }

    const purchaseOrderControls = {
        savePurchaseOrder: purchaseOrderActions.save,
        deletePurchaseOrder: purchaseOrderActions.delete,
        cancelPurchaseOrder: purchaseOrderActions.cancel,
        addNewRow,
        doBookkeeping,
    }
    const orderLineControls = {
        deleteEditRow,
        setSugBoxEditRowLinkIdx
    }

    const editRowsTsx = <EditRows purchaseOrderControls={purchaseOrderControls} orderLineControls={orderLineControls} editRows={editRows} handlerFieldChange={handlerFieldChange} purchaseBeingEdited={purchaseBeingEdited} setShowProdSearch={ ( input: boolean ) => { setShowProdSearch(input) } } />
    const outputRows: JSX.Element[] = [];
    const purchaseIdEdited = editRows.length > 0 ? editRows[0].purchaseId : undefined;
    (function editNewPurchase() {
        if( purchaseIdEdited !== undefined && !supplierPurchaseOrders.some( order => order.id === purchaseBeingEdited))
        {
            outputRows.push( editRowsTsx )
            return;
        }
    })()

    const searchableResProd = createSearchableResProd( reservedProducts )
    supplierPurchaseOrders.forEach( ( order, i) => {
        if( purchaseIdEdited !== undefined && purchaseIdEdited === order.id )
        {
            outputRows.push( editRowsTsx )
            return;
        }

        order.data.forEach( orderLine => {
            outputRows.push( dataRow( orderLine, suppliers, purchaseOrderActions.edit, searchableResProd, hideRowsWithNoneInStock, order.bookkeepingData ) )
        })
    })
    return (
        <div>
            {modal}
            <button disabled={editRows.length > 0} onClick={ () => { addNewPurchase() } }>+ Add Purchase</button>
            <button onClick={ () => { setHideRowsWithNoneInStock(!hideRowsWithNoneInStock) } }>{hideRowsWithNoneInStock ? 'Show Empty Rows' : 'Hide Empty Rows'}</button>
            <Table>
                <thead>
                    <TrHead>
                        <Th></Th>
                        <Th></Th>
                        <Th></Th>
                        <Th>PurchaseId</Th>
                        <Th>Supplier</Th>
                        <Th>insertTime</Th>
                        {/* <Th>receivedTime</Th> */}
                        <Th>internalSku</Th>
                        <Th>supplierSku</Th>
                        <Th>productName</Th>
                        <Th>qty</Th>
                        <Th>price</Th>
                        {/* <Th>status</Th> */}
                        {/* <Th>inStock</Th> */}
                        {/* <Th>sentTime</Th> */}
                        <Th>Stock</Th>
                    </TrHead>
                </thead>
                <tbody>
                    {outputRows}
                    
                </tbody>
            </Table>
            <SearchSuggestions setShowProdSearch={setShowProdSearch} cbSelectProduct={cbSelectProduct} isVisibleProductSearch={showProdSearch} prodSupplierArrayFiltered={prodSupplierArrayFiltered} />
        </div>
    )
}

function dataRow( orderLine: ISupplierPurchaseLine, suppliers: ISupplier[], cbEditPurchaseOrder: ( purchaseId: number ) => void, searchableResProd: ISearchableResProd[], hideRowsWithNonInStock: boolean, bookkeepingData?: IBookeepingApiMsg ) {
    const { 
        purchaseId,
        supplierId,
        insertTime,
        receivedTime,
        internalSku,
        supplierSku,
        productName,
        qty,
        price,
        status,
        sentTime,
    } = orderLine

    const supplier = ( () => {
        const finding = suppliers.find( s => s.id === supplierId )
        if(finding === undefined) return '-';
        return finding.name
    })();

    const resProdInvoiceIds = getResProduct( searchableResProd, purchaseId, internalSku )
    const stockLeft = qty - resProdInvoiceIds.length

    const isService = internalSku.indexOf('purchase') > -1
    const reservationTd = isService ? <Td></Td> : <Td bgColor={stockLeft === 0 ? "#9cef9c" : "White"} onClick={ () => { alert() } }>Left {stockLeft} - {resProdInvoiceIds.join(', ')}</Td> 
    return (
        <Tr hidden={isService || (hideRowsWithNonInStock && stockLeft === 0)} key={purchaseId + '-' + internalSku}>
            <Td><button onClick={ () => { cbEditPurchaseOrder( purchaseId ) }}>Edit</button></Td>
            <Td></Td>
            <Td>{bookkeepingData?.internalVoucherId}<br />{bookkeepingData?.invoiceDate}</Td>
            <Td>{purchaseId}</Td>
            <Td>{supplier}</Td>
            <Td>{unixToDateTimeStringTwoLines(insertTime)[0]}<br />{unixToDateTimeStringTwoLines(insertTime)[1]}</Td>
            {/* <Td>{unixToDateTimeString(receivedTime)}</Td> */}
            <Td>{internalSku}</Td>
            <Td>{supplierSku}</Td>
            <Td>{productName}</Td>
            <Td rightAlign={true}>{qty}</Td>
            <Td rightAlign={true}>{twoDigitString(price)}</Td>
            {/* <Td>{status}</Td> */}
            {/* <Td>{x.inStock}</Td> */}
            {/* <Td>{sentTime}</Td> */}
            {reservationTd}
        </Tr>
    )
}

function filterProdSupplierArray( purchaseOrders: IPurchaseOrder[], filter: IFilterInput ): IProdSupplier[] {
    const output: string[] = [];

    // const sortedPurchaseOrders = {...purchaseOrders};
    // sortedPurchaseOrders.sort((a, b) => a.id > b.id ? 1 : -1);

    for(const order of purchaseOrders) {
        for(const line of order.data) {
            const {
                supplierId,
                internalSku,
                supplierSku,
                productName,
                price,
                qty
            } = line;
            
            const statisfiesSupplierId = parseInt(filter.supplierId) === -1 ? true: supplierId === parseInt(filter.supplierId)
            const statisfiesName = productName.toLowerCase().indexOf( filter.productName.toLowerCase() ) !== -1
            const statisfiesSku = internalSku.toLowerCase().indexOf( filter.internalSku.toLowerCase() ) !== -1
            const statisfiesSupplierSku = supplierSku.toLowerCase().indexOf( filter.supplierSku.toLowerCase() ) !== -1
            if (statisfiesSupplierId && statisfiesName && statisfiesSku && statisfiesSupplierSku)
            {
                output.push(JSON.stringify({
                    supplierId,
                    sku: internalSku,
                    name: productName,
                    supplierSku,
                    price: Math.round(price / qty)
                }))
            }
        }
    }

    const filtered = Array.from(new Set(output)).map(x => JSON.parse(x) as IProdSupplier);
    console.log({filtered})
    return filtered
}

enum StatusToShow {
    all = "all",
    completed = "completed",
    processing = "processing"
}


interface ISupplierProduct {
    sku: string,
    batteryId: string,
    batteryVariationId: string,
    batteryImageName: string,
    putOnWebsite: string,
    noInLocalStock: number,
    deliveryTime: string,
    alwaysInStock: boolean,
    batteryBrand: string,
    supplierId: number,
    supplier: string,
    supplierProductId: string,
    voltage: string,
    ah: string,
    color: string,
    internalProductName: string,
    supplierProductName: string,
    supplierPrice: string,
    isInStock: string,
}

export interface IProdSupplier {
    supplierId: number,
    sku: string,
    name: string,
    supplierSku: string,
    price: number,
}
interface IFilterInput {
    supplierId: string,
    internalSku: string,
    supplierSku: string,
    productName: string
}

function setAttr(editRows: ISupplierPurchaseLine[], editRowIdx: number, attrName: string, dataType: enumDataType, val: string) {
    return editRows.map((x, i) => {
        if( i !== editRowIdx ) return x;
        const castedValue = (() => {
            if(dataType === enumDataType.string) {
                return val.trim()
            }
            if(dataType === enumDataType.int) {
                return parseInt(val)
            }
            if(dataType === enumDataType.int100) {
                return Math.round(parseFloat(val) * 100)
            }
            if(dataType === enumDataType.float) {
                return parseFloat(val)
            }
        })()

        return {
            ...x,
            [attrName]: castedValue
        }
    })
}

function createSearchableResProd( reservedProducts: IReservedProducts[]  ) {
    const outputArr: ISearchableResProd[] = []
    reservedProducts.forEach( resItem => {
        resItem.items.forEach( resProd => {
            outputArr.push({ supplierPurchaseId: resProd.supplierPurchaseId, invoiceNumber: resItem.shopInvoiceId, sku: resProd.sku })
        } )
    })
    return outputArr
}

function getResProduct( hayStack: ISearchableResProd[], purchaseId: number, sku: string ) {
    const invoiceNumbers: number[] = hayStack.filter( x => x.sku === sku && x.supplierPurchaseId === purchaseId).map( x => x.invoiceNumber )
    return invoiceNumbers
}

interface ISearchableResProd {
    supplierPurchaseId: number,
    invoiceNumber: number,
    sku: string
}

export interface IPurchaseOrderControls {
    savePurchaseOrder: () => void;
    deletePurchaseOrder: ( purchaseId: number ) => void;
    cancelPurchaseOrder: () => void;
    addNewRow: (purchaseId: number, shippingType?: enumOrderLineType) => void;
    doBookkeeping: (purchaseId: number) => void;
}

export interface IOrderLineControls {
    deleteEditRow: (idx: number) => void;
    setSugBoxEditRowLinkIdx: (idx: number) => void;
}

export enum enumDataType {
    string = 'string',
    int = 'int',
    int100 = 'int100',
    float = 'float'
}