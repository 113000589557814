import React from 'react'

import { TdInputTxt, TdInputSelect, TdInputOption } from './index.style'
import { Table, ScrollWrapper, TrHead, Tr, Th, Td, FloatWrapper, Tbody, Close } from './search-suggestions.style'
import { IProdSupplier } from './index'
import { suppliers } from '../shared'

export default function SearchSuggestions( props: { setShowProdSearch: ( input: boolean ) => void, cbSelectProduct: (selectedItem: IProdSupplier) => void, isVisibleProductSearch: boolean, prodSupplierArrayFiltered: IProdSupplier[] }) {
    const { setShowProdSearch, cbSelectProduct, isVisibleProductSearch, prodSupplierArrayFiltered } = props


    const prodListContent = prodSupplierArrayFiltered.map( prod => {
        let _supplierName = suppliers.find( x => prod.supplierId === x.id )
        const supplierName = _supplierName ? _supplierName.name : ''
        return (
        <Tr onClick={() => { cbSelectProduct({
            supplierId: prod.supplierId,
            sku: prod.sku,
            name: prod.name,
            supplierSku: prod.supplierSku,
            price: prod.price,
        });setShowProdSearch(false); }} key={JSON.stringify(prod)}>
            <Td index={1}>{supplierName}</Td>
            <Td index={2}>{prod.name}</Td>
            <Td index={3}>{prod.sku}</Td>
            <Td index={4}>{prod.supplierSku}</Td>
            <Td index={5}>{(prod.price/100).toFixed(2)}</Td>
        </Tr>
    )})

    return (

        <FloatWrapper hidden={!isVisibleProductSearch}>
            <Close onClick={() => {setShowProdSearch(false);}} />
            <Table>
                <thead>
                    <TrHead>
                        <Th index={1}>Supplier</Th>
                        <Th index={2}>Name</Th>
                        <Th index={3}>Sku</Th>
                        <Th index={4}>SupplierSku</Th>
                        <Th index={5}>Price</Th>
                    </TrHead>
                </thead>
            </Table>
            <ScrollWrapper>
                <Table>
                    <colgroup>
                        <col style={{ width: "200px" }} />
                        <col style={{ width: "300px" }} />
                        <col style={{ width: "200px" }} />
                        <col style={{ width: "200px" }} />
                        <col style={{ width: "100px" }} />
                    </colgroup>
                    <Tbody>
                        {prodListContent}
                    </Tbody>
                </Table>
            </ScrollWrapper>
        </FloatWrapper>
    )
}
