import { firestore } from 'firebase'
import { hash } from '../helpers';
export default class Database {
  db: firebase.firestore.Firestore
  constructor () {
    this.db = firestore();
  }

  // async getData() {
  //   const col = this.db.collection("BatteryFinderData")
  //   const results = await col.orderBy('insertTimestamp', 'desc').limit(1).get()
  //   const data = JSON.parse(results.docs.map((x: any) => x.data())[0].data)
  //   console.log(1234)
  //   console.log({data})
  //   return data
  // }

  subscribeToSupplierPurchases(cb: ( input: any[]) => void) {
    return genericSnapshotHandler( dbNames.SupplierPurchase, this.db, cb, 'id', '50000', IOperator.Gt )
  }

  subscribeToSupplierPurchasesSuggestions(cb: ( input: any[]) => void) {
    return genericSnapshotHandler( dbNames.SupplierPurchase, this.db, cb, 'id', '40000', IOperator.Gt )
  }

  subscribeToOrderExtension(cb: ( input: any[]) => void) {
    return genericSnapshotHandler( dbNames.OrderExtension, this.db, cb )
  }

  subscribeToReservedProduct(cb: ( input: any[]) => void) {
    return genericSnapshotHandler( dbNames.ReservedProduct, this.db, cb )
  }
  
  subscribeToStock(cb: ( input: any[]) => void) {
    return genericSnapshotHandler( dbNames.Stock, this.db, cb )
  }
  
  subscribeToLoggerSources(cb: ( input: any[]) => void) {
    return genericSnapshotHandler( dbNames.LoggerSources, this.db, cb )
  }
  
  subscribeToScrapedData(cb: ( input: any[]) => void) {
    const days = 7
    const date = new Date(Date.now())
    const timestamp = Math.round((date.setDate(date.getDate() - days)) / 1000);
    return genericSnapshotHandler( dbNames.ScraperCollected, this.db, cb, 'timestamp', new firestore.Timestamp(timestamp, 0 ), IOperator.Gt )
  }
  
  subscribeToLoggerData(cb: ( input: any[]) => void) {
    const days = 21
    const date = new Date(Date.now())
    const timestamp = Math.round((date.setDate(date.getDate() - days)) / 1000);
    return genericSnapshotHandler( dbNames.LoggerData, this.db, cb, 'insertTimestamp', new firestore.Timestamp(timestamp, 0 ), IOperator.Gt )
  }
  
  subscribeToOverwriteInvoiceProduct(cb: ( input: any[]) => void) {
    return genericSnapshotHandler( dbNames.OverwriteInvoiceProduct, this.db, cb )
  }

  async initOrderExtension( ids: number[] ) {
    for( const id of ids ) {
      const data: IOrderExtension = {
        id,
        checkMoneyReserved: false,
        checkMoneyCharged: false,
        checkMoneyReceived: false,
        checkPackageSent: false,
        checkTrackMail: false,
        checkBookkept: false,
        productStatus: EnumOrderProductStatus.NA,
      }

      if( await this.documentExists( dbNames.OrderExtension, id + '' ) ) continue;

      const document = this.db.collection(dbNames.OrderExtension).doc( data.id + '' )
      await document.set( data )
    }
  }

  async setOrderExtension( data: IOrderExtension ) {
    const document = this.db.collection(dbNames.OrderExtension).doc( data.id + '' )
    await document.set( data )
  }

  async setOrderBookkept( orderId: number ) {
    const doc = await this.db.collection(dbNames.OrderExtension).doc( orderId + '' )
    await doc.update({checkBookkept: true})
  }

  async addSupplierPurchase( data: ISupplierPurchaseLine[] ) {
    // const highestId = await this.getMaxSupplierPurchaseId()
    // const nextId = highestId + 1
    const document = this.db.collection(dbNames.SupplierPurchase).doc( data[0].purchaseId + '' )
    
    data = updateInsertTime(data)
    await document.set( { data: data.map( x => cleanUndefined(x) ), id: data[0].purchaseId } )
  }
  async updateSupplierPurchaseBookkeepingData( purchaseId: number, bookkeepingData: any ) {
    // const highestId = await this.getMaxSupplierPurchaseId()
    // const nextId = highestId + 1
    const document = this.db.collection(dbNames.SupplierPurchase).doc( purchaseId + '' )
    await document.update({bookkeepingData})
  }
  
  // async addReservedProduct( data: IReservedProducts ) {
  //   const document = this.db.collection(dbNames.ReservedProduct).doc( data.shopInvoiceId + '' )
  //   await document.set( {
  //     data
  //   } )
  // }
  async addReservedProduct( shopInvoiceId: number, purchaseId: number, sku: string ) {
    const document = this.db.collection(dbNames.ReservedProduct).doc( shopInvoiceId + '' )
    const existingEntry = await (async () => {
      const snapshot = await document.get()
      if( !snapshot.exists ) return undefined
      const data = snapshot.data() as IReservedProducts
      return data;
    })();

    if(existingEntry === undefined) {
      const dataToInsert: IReservedProducts = {
        shopInvoiceId,
        items: [{
          supplierPurchaseId: purchaseId,
          sku,
          insertTime: unixNow(),
        }]
      }
      await document.set( dataToInsert )
    } else {
      const items = [...existingEntry.items, {
        supplierPurchaseId: purchaseId,
        sku,
        insertTime: unixNow(),
      }]
      await document.set( { shopInvoiceId: existingEntry.shopInvoiceId, items } )
    }
  }

  async deleteSupplierPurchase( purchaseId: number ) {
    // const highestId = await this.getMaxSupplierPurchaseId()
    // const nextId = highestId + 1
    const document = this.db.collection(dbNames.SupplierPurchase).doc( purchaseId + '' )
    document.delete()
  }

  async getMaxSupplierPurchaseId(): Promise<number> {
    const results = await this.db.collection(dbNames.SupplierPurchase).orderBy("id", 'desc').limit(1).get()
    const data = results.docs.map((x: any) => x.data())
    if( data.length === 0 ) return 0
    return data[0].id
  }

  async documentExists( dbName: dbNames, docId: string ) {
    const document = this.db.collection(dbName).doc( docId )
    const snapshot = await document.get()
    return snapshot.exists
  }
  
  async setLoggerSource(source: ILoggerSource) {
    const document = this.db.collection(dbNames.LoggerSources).doc(source.name)
    const fetchedDocument = await document.get()
    if(fetchedDocument.exists) {
      const currentSourceData = fetchedDocument.data() as ILoggerSource;
      if(!currentSourceData) return;
      
      currentSourceData.type = Array.from(new Set([...currentSourceData.type, ...source.type]))
      await document.set({...currentSourceData});
    } else {
      await document.set(source);
    }
  }

  async setOverwriteInvoiceProduct(params: IRewriteProduct) {
    const document = this.db.collection(dbNames.OverwriteInvoiceProduct).doc(`${params.invoiceNumber}-${hash(params.itemName)}`)
    const fetchedDocument = await document.get()
    await document.set(params);
  }
}

function updateInsertTime( data: ISupplierPurchaseLine[] ) {
  return [...data.map( x => {
    return {
      ...x,
      insertTime: x.insertTime > -1 ? x.insertTime : unixNow() 
    }
  })]
}
function updateReceivedTime( data: ISupplierPurchaseLine[] ) {
  const dataOutput: ISupplierPurchaseLine[] = []
  
}

function cleanUndefined(obj: any) {
  let outputObj: any = {}
  Object.keys(obj).forEach( key => {
    const val = obj[key]
    if( val === undefined ) return;
    outputObj[key] = val
  })
  return outputObj
}

function unixNow() {
  return Math.floor(Date.now() / 1000)
}

let count = 0
function genericSnapshotHandler(dnName: dbNames, db: firestore.Firestore, cb: ( input: any[] ) => void, key?: string, value?: string | firestore.Timestamp, operator?: IOperator ): () => void {
  const coll = ( () => {
    if(key && value) {
      const dbOperator = ( () => {
        if(operator === IOperator.Equal) return "==";
        if(operator === IOperator.Gt) return ">";
        if(operator === IOperator.Lt) return "<";
        return "==";
      })();

      if ( typeof value === 'string' ) return db.collection(dnName).where(key, dbOperator, parseInt(value))

      return db.collection(dnName).where(key, dbOperator, value)
    }
    return db.collection(dnName)
  })()
  let unsubscribe = coll.onSnapshot(function(querySnapshot: any) {
    count++
    console.log(dnName + count)
        let data: any[] = [];
        querySnapshot.forEach(function(doc: any) {
            const document = doc.data() as any
            data.push(document)
        });
        cb( data )
  });
  return unsubscribe
}

export enum IOperator {
  Equal = "Equal",
  Gt = "Gt",
  Lt = "Lt",
}

export enum dbNames {
  ReservedProduct = "ReservedProduct",
  SupplierPurchase = "SupplierPurchase",
  OrderExtension = "OrderExtension",
  Stock = "Stock",
  LoggerData = "LoggerData",
  LoggerSources = "LoggerSources",
  ScraperCollected = "ScraperCollected",
  OverwriteInvoiceProduct = "OverwriteInvoiceProduct",
}

export interface IOrderExtension {
  id: number,
  checkMoneyReserved: boolean,
  checkMoneyCharged: boolean,
  checkMoneyReceived: boolean,
  checkPackageSent: boolean,
  checkTrackMail: boolean,
  checkBookkept: boolean,
  productStatus: EnumOrderProductStatus,
}

export interface ISupplierPurchaseLine {
  purchaseId: number
  supplierId: number,
  insertTime: number,
  receivedTime?: number
  internalSku: string,
  supplierSku: string,
  productName: string,
  qty: number,
  price: number,
  status: EnumStatus,
  // inStock: boolean,
  sentTime?: number,
}
export interface ISupplierPurchase {
  purchaseId: number
  supplierId: number,
  insertTime: number,
  receivedTime?: number
  internalSku: string,
  supplierSku: string,
  productName: string,
  qty: number,
  price: number,
  status: EnumStatus,
  // inStock: boolean,
  sentTime?: number,
}

export interface IReservedProducts {
  shopInvoiceId: number,
  items: IReservedProduct[]
}
export interface IReservedProduct {
  supplierPurchaseId: number,
  sku: string,
  insertTime: number,
  unitPrice?: number,
}

export interface IRewriteProduct {
  invoiceNumber: string;
  itemName: string;
  newSku: string;
}

// export ISentToEconomic {
//   sentTime?: number,

// }

export enum EnumStatus {
  na = "NA",
  OnTheWay = "Ordered",
  OnTheWayLong = "Ordered 3-5d",
  Received = "Received",
  Unknown = "Unknown",
}

export enum EnumOrderProductStatus {
  NA = "NA",
  NeedToOrder = "NeedToOrder",
  WaitingForSupplierToSend = "WaitingForSupplierToSend",
  SupplierSent = "SupplierSent",
  Unknown = "Unknown",
}


// Logger
type LoggerCommon = {
  source: string,
  date: string,
  insertTimestamp: firestore.Timestamp,
}

export enum LoggerTypes {
  heartbeat = 'heartbeat',
  error = 'error',
}

export interface ILoggerHeartbeatEntry extends LoggerCommon {
type: LoggerTypes.heartbeat,
}

export interface ILoggerErrorEntry extends LoggerCommon {
type: LoggerTypes.error,
  message: string,
}

export type ILoggerEntry = ILoggerHeartbeatEntry | ILoggerErrorEntry;
export type ILoggerEntryInsert = Omit<ILoggerHeartbeatEntry, 'insertTimestamp' | 'date'> | Omit<ILoggerErrorEntry, 'insertTimestamp' | 'date'>

export interface ILoggerSource {
  name: string,
  type: LoggerTypes[],
}

export interface IScrapeSession extends IEntityScrapeBattery {
  date: string
}

export interface IEntityScrapeBattery {
  supplierName: string,
  timestamp: firestore.Timestamp,
  secondsTaken: number,
  productArray: IEntityScapeBatteryProduct[]
}

export interface IEntityScapeBatteryProduct {
  supplierProductNumber: string,
  batteryPlacement: string,
  title: string,
  link: string,
  //details: string,
  stockStatus: string,
  stockStatusDescription?: string,
  price: number
}