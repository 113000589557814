import styled from 'styled-components'

export const ModalContainer = styled.div`
    overflow:hidden;
    height:0px;
    width:0px;
`

export const ScreenOverlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position:fixed;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    z-index:10000000;
    cursor: pointer;
`

export const ModalWrapper = styled.div<{ mobileView: boolean, height: number, width: number}>`
    ${props => props.mobileView ? `
        height: 100vh;
        width: 100vw;
        top: 0;
        : 0;
    ` : `
        height: ${props.height}px;
        width: ${props.width}px;
        top: calc(50% - ${Math.floor(props.height / 2)}px);
        left: calc(50% - ${Math.floor(props.width / 2)}px);
    `}
    border: 1px solid gray;
    border-radius: 3px;
    position:fixed;
    background-color: White;
    z-index:10000001;
    padding:20px;
    padding-top:50px;

    display : flex;
    align-items : center;
    justify-content: center;
`

export const CloseModal = styled.img`
    height: 40px;
    width: 40px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
`

export const Content = styled.div`
`
