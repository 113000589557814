
import { auth, initializeApp, firestore, app } from 'firebase'

let isAuthorized = false
let authConn: auth.Auth
authorize()

export async function authorize() {
    const config = {
      apiKey: "AIzaSyC8Xbz-gO1SsehQmMutTEGFnkNsBhBWcAE",
      authDomain: "p2bike-internal.firebaseapp.com",
      databaseURL: "https://p2bike-internal.firebaseio.com",
      projectId: "p2bike-internal",
      storageBucket: "p2bike-internal.appspot.com",
      messagingSenderId: "766383765897"
    };
    initializeApp(config);
    
    authConn = await auth()
    console.log("Authed")
    isAuthorized = true
}

export async function signIn(user: string, pass: string) {
    const feedback = await authConn.signInWithEmailAndPassword(user, pass)
    if(feedback.operationType === "signIn") {
      authConn.onAuthStateChanged(async state => {
        const currentUser = authConn.currentUser
        console.log({currentUser})
        if(currentUser) {
            const token = await currentUser.getIdToken()
            return token
        }
      })
    }
}

export async function isSignedIn() {
  console.log('Waiting')
    const authConn = await waitTillAuthed() as auth.Auth
    console.log('Signed In')
    const currentUser = authConn.currentUser
    if(currentUser !== null) {
      console.log("Already logged in")
      const token = await currentUser.getIdToken()
      const email = currentUser.email
      return { token, email };
    }
}

async function waitTillAuthed() {
  for( let i = 0; i < 1000; i++ ) {
    await new Promise( res => {
      setTimeout( ()=> {
        res('');
      }, 100)
    })
    if(isAuthorized && authConn.currentUser != null) return authConn;
  }
}


export async function signOut() {
  const authConn = await waitTillAuthed() as auth.Auth
  authConn.signOut();
}