import styled from 'styled-components'

export const ErrorMessage = styled.div`
    word-break: break-all;
    font-family: monospace;
    white-space: pre;
    line-height: 12px;

`
export const RotatedText = styled.div`
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    font-size: 14px;
    height: 58px;
    line-height: 10px;
    width: 10px;
    text-align: center;
    margin-bottom: 10px;
`
export const Small = styled.div`
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    font-size: 13px;
    height: 58px;
    line-height: 42px;
    text-align: center;
    color: #fff;
`