import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './App.css';
import Header from './components/header'
import OrderComponent from './components/order-component'
import SupplierComponent from './components/supplier-component'
import MonitoringComponent from './components/monitoring-component'
import StartTaskComponent from './components/start-task-component'
import ScrapedDataComponent from './components/scraped-data-component'
import { ContentArea } from './App.style'
import { signIn, isSignedIn, signOut } from './login'
import Database, { IOrderExtension, dbNames, ISupplierPurchaseLine, IReservedProducts, ILoggerEntry, ILoggerSource, LoggerTypes, IEntityScrapeBattery, IScrapeSession, IRewriteProduct } from './firestore'
import { IBookeepingApiMsg } from './components/bookkeeping-component/shared';
import StockComponent from './components/stock-component';

// db.getData();
const db = new Database()
function App() {
    const [ signedIn, setSignedIn ] = useState( false )
    const [ signedInAs, setSignedInAs ] = useState( '' )
    const [ orderExtensionArray, setOrderExtension ] = useState<IOrderExtension[]>( [] )
    const [ supplierPurchaseOrders, setSupplierPurchaseOrders ] = useState<IPurchaseOrder[]>( [] )
    const [ supplierPurchaseOrderSuggestions, setSupplierPurchaseOrderSuggestions ] = useState<IPurchaseOrder[]>( [] )
    const [ reservedProducts, setReservedProducts ] = useState<IReservedProducts[]>( [] )
    const [ unreservedProducts, setUnreservedProducts ] = useState<IUnreservedProduct[]>( [] )
    const [ loggerSources, setLoggerSources ] = useState<ILoggerSource[]>( [] )
    const [ rewrittenProducts, setRewriteProducts ] = useState<IRewriteProduct[]>( [] )
    const [ loggerData, setLoggerData ] = useState<ILoggerEntry[]>( [] )
    const [ scrapedData, setScrapedData ] = useState<IScrapeSession[]>( [] )
    useEffect(() => {
        setUnreservedProducts(getUnreservedProducts(supplierPurchaseOrders, reservedProducts))
      }, [supplierPurchaseOrders, reservedProducts]);

    useEffect( () => {
        isSignedIn().then( async ( answer ) => {
            if( answer ) {
                setSignedIn( true )
                setSignedInAs( answer.email || '' )
                console.log('Subscribing')
                db.subscribeToOrderExtension( ( orderExt: IOrderExtension[] ) => { setOrderExtension( orderExt ) } )
                db.subscribeToSupplierPurchases( ( purchaseArr: IPurchaseOrder[] ) => {
                    setSupplierPurchaseOrders( purchaseArr.sort( (a,b) => a.id < b.id ? 1 : -1 ) );
                })
                db.subscribeToSupplierPurchasesSuggestions( ( purchaseArr: IPurchaseOrder[] ) => {
                    setSupplierPurchaseOrderSuggestions( purchaseArr.sort( (a,b) => a.id < b.id ? 1 : -1 ) );
                })
                db.subscribeToReservedProduct( ( prods: IReservedProducts[] ) => { setReservedProducts( prods );} )
                    
                db.subscribeToLoggerData( ( loggerEntry: ILoggerEntry[] ) => {
                    const output = loggerEntry.map( entry => {
                        entry.source = entry.source.replace(/\"/g, '')
                        if( entry.type === LoggerTypes.heartbeat ) return entry;
                        entry.message = entry.message.replace(/\\r/g, '').replace(/\\n/g, '\n')

                        return entry;
                    })
                    setLoggerData( output );
                } )
                db.subscribeToLoggerSources( ( loggerSource: ILoggerSource[] ) => { setLoggerSources( loggerSource );} )
                db.subscribeToScrapedData( ( scrapedData: IEntityScrapeBattery[] ) => {
                    const scrapedSession = scrapedData.map( item => ({
                        ...item,
                        date: item.timestamp.toDate().toISOString().substr(0, 10)
                    }))
                    setScrapedData( scrapedSession )
                })
                db.subscribeToOverwriteInvoiceProduct( ( products: IRewriteProduct[] ) => { setRewriteProducts( products );} )
            }
            // db.addSupplierPurchase({id:215})
            // db.addSupplierPurchase({id:213})
            // db.addSupplierPurchase({id:214})
            // const id = await db.getMaxSupplierId()
            // console.log( { id } )
            
        })
    }, [])

    const submitLogin = async ( user: string, pass: string ) => {
        //await authorize()
        try {
            await signIn(user, pass)
            setSignedIn(true)
            return true
        } catch(e) {
            const code = (e as any).code
            if( code !== undefined ) {
                alert(code)
            } else {
                alert("Unknown login error")
            }
            return false
        }
    }

    const _signOut = async () => {
        await signOut();
        window.location.href = window.location.href
    }
    const reservedProductsWithPrice = addPriceData( reservedProducts, supplierPurchaseOrders )
    return (
        <div>
            <Router>
                <Header signOut={_signOut} signedInAs={signedInAs} isSignedin={signedIn} submitLogin={submitLogin} />
                <ContentArea>
                    <Switch>
                        <Route path="/date/:selectedDate">
                        </Route>
                        <Route path="/supplier">
                            <SupplierComponent db={db} isSignedIn={signedIn} supplierPurchaseOrders={supplierPurchaseOrders} supplierPurchaseOrderSuggestions={supplierPurchaseOrderSuggestions} reservedProducts={reservedProducts} />
                        </Route>
                        <Route path="/stock">
                            <StockComponent isSignedIn={signedIn} supplierPurchaseOrders={supplierPurchaseOrders} reservedProducts={reservedProducts} />
                        </Route>
                        <Route path="/monitoring">
                            <MonitoringComponent db={db} isSignedIn={signedIn} loggerData={loggerData} loggerSources={loggerSources} />
                        </Route>
                        <Route path="/start-task">
                            <StartTaskComponent isSignedIn={signedIn} />
                        </Route>
                        <Route path="/scraped-data">
                            <ScrapedDataComponent db={db} isSignedIn={signedIn} scrapedData={scrapedData} />
                        </Route>
                        <Route path="/">
                            <OrderComponent db={db} isSignedIn={signedIn} orderExtensionArray={orderExtensionArray} reservedProducts={reservedProductsWithPrice} unreservedProducts={unreservedProducts} rewrittenProducts={rewrittenProducts} />
                        </Route>
                    </Switch>
                </ContentArea>
            </Router>
        </div>
    );
}

function addPriceData( reservedProducts: IReservedProducts[], purchases: IPurchaseOrder[] ) {
    const getUnitPrice = ( purchaseId: number, sku: string ) => {
        const order = purchases.find( x => x.id === purchaseId )
        if( order === undefined) return 0;
        const data = order.data
        if( data === undefined) return 0;
        const foundProduct = data.find( x => x.internalSku === sku )
        if( foundProduct === undefined) return 0;

        return Math.round( foundProduct.price / foundProduct.qty )
    }

    const output: IReservedProducts[] = reservedProducts.map( res => {
        return {
            ...{
                ...res,
                items: res.items.map( item => {
                    return {
                        ...item,
                        price: item.unitPrice = getUnitPrice( item.supplierPurchaseId, item.sku )
                    }
                })
            }
        }
    })
    return output
}

function getUnreservedProducts( purchases: IPurchaseOrder[], reservedProducts: IReservedProducts[] ) {
    const purchasesSorted = [...purchases].sort( (a, b) => a.id > b.id ? 1 : -1)
    const reservedProductsFlattened: IReservedProductFlattened[] = ( () => {
        const output: IReservedProductFlattened[] = []
        reservedProducts.forEach( item => {
            item.items.forEach(element => {
                output.push({
                    purchaseId: element.supplierPurchaseId,
                    sku: element.sku,
                    purchaseUnitPrice: 0,
                    purchaseDate: "",
                })
            });
        })
        return output
    })();

    const unreservedProducts: IUnreservedProduct[] = []
    const outReservedProductsFlattened: IReservedProductFlattened[] = []
    purchasesSorted.forEach( purchase => {
        purchase.data.forEach( purProd => {
            for( let i = 0; i < purProd.qty; i++ ) {
                const idx = reservedProductsFlattened.findIndex( res => res.purchaseId === purchase.id && res.sku === purProd.internalSku )
                const foundReservedProduct = idx > -1

                if(foundReservedProduct) {
                    reservedProductsFlattened.splice(idx, 1);
                    outReservedProductsFlattened.push({ purchaseId: purProd.purchaseId, sku: purProd.internalSku, purchaseUnitPrice: Math.round( purProd.price / purProd.qty ), purchaseDate: '' })
                } else {
                    unreservedProducts.push({ purchaseId: purProd.purchaseId, sku: purProd.internalSku, purchaseUnitPrice: Math.round( purProd.price / purProd.qty ), purchaseDate: '' })
                }
            }
        })
    })

    return unreservedProducts
}

export interface IUnreservedProduct {
    purchaseId: number,
    sku: string,
    purchaseUnitPrice: number,
    purchaseDate: string,
}
export type IReservedProductFlattened = IUnreservedProduct

// async function OrderList() {
//   const orders = await getOrders()
//   return <OrderComponent />
// }

export interface IPurchaseOrder {
    id: number;
    data: ISupplierPurchaseLine[];
    bookkeepingData?: IBookeepingApiMsg;
}


export default App;
