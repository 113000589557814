import React, { useState } from 'react'
import { Logo, HeaderWrapper, MenuItems, MenuItem, MenuItemLink, LoginArea, User, Pass, Submit, InputUser, InputPass, SubmitButton, SignedInAs } from './index.style'
import LogoSrc from './images/logo-inverted.png'

export default function Header(props: { submitLogin: ( user: string, pass: string ) => Promise<boolean>, isSignedin: boolean, signedInAs: string, signOut: () => void }) {
    const { submitLogin, isSignedin, signedInAs, signOut } = props
    const [ getUser, setUser ] = useState<string>("")
    const [ getPass, setPass ] = useState<string>("")

    return (
        <HeaderWrapper>
            <Logo src={LogoSrc} />
            <MenuItems>
                <MenuItem>
                    <MenuItemLink href="/">Orders</MenuItemLink>
                </MenuItem>
                <MenuItem>
                    <MenuItemLink href="/supplier">Supplier</MenuItemLink>
                </MenuItem>
                <MenuItem>
                    <MenuItemLink href="/stock">Stock</MenuItemLink>
                </MenuItem>
                <MenuItem>
                    <MenuItemLink href="/monitoring">Monitoring</MenuItemLink>
                </MenuItem>
                <MenuItem>
                    <MenuItemLink href="/start-task">Start Task</MenuItemLink>
                </MenuItem>
                <MenuItem>
                    <MenuItemLink href="/scraped-data">Scraped Data</MenuItemLink>
                </MenuItem>
            </MenuItems>
            <LoginArea hidden={isSignedin}>
                <User>
                    <InputUser onChange={( e ) => { 
                        setUser( e.target.value )
                    }} type="text" name="user" placeholder="User Name" />
                </User>
                <Pass>
                    <InputPass onChange={( e ) => { 
                        setPass( e.target.value )
                    }} onKeyPress={ ( e ) => {
                        if( e.key === 'Enter' ) submitLogin(getUser, getPass)
                    } } type="password" name="pass" placeholder="Password" />
                </Pass>
                <Submit>
                    <SubmitButton onClick={ async () => {
                        const success = submitLogin(getUser, getPass)
                    } }>Login</SubmitButton>
                </Submit>
            </LoginArea>
            <SignedInAs hidden={!isSignedin} onClick={ () => { signOut(); } }>
                Signed in as { signedInAs } {getUser}
            </SignedInAs>
        </HeaderWrapper>
    )
}
