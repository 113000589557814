import React, { useState, useEffect } from "react";
import EditRow from "./edit-row";
import Database, {
	ISupplierPurchaseLine,
	EnumOrderProductStatus,
	EnumStatus,
} from "../../firestore";
import {
	Table,
	TrHead,
	Tr,
	Th,
	Td,
	TableControls,
	TableControlItem,
	ControlLabel,
	BtnStatus,
} from "../table.style";
import { IPurchaseOrderControls, IOrderLineControls } from ".";
import { enumOrderLineType } from './../shared'

export default function EditRows(props: {
    purchaseOrderControls: IPurchaseOrderControls;
    orderLineControls: IOrderLineControls;
	purchaseBeingEdited: number | undefined;
	editRows: ISupplierPurchaseLine[];
	handlerFieldChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => void;
	setShowProdSearch: (input: boolean) => void;
}) {
	const {
		// savePurchaseOrder,
		// cancelPurchaseOrder,
		// deleteEditRow,
		// setSugBoxEditRowLinkIdx,
        // addNewRow,
        purchaseOrderControls,
        orderLineControls,
		purchaseBeingEdited,
		editRows,
		handlerFieldChange,
		setShowProdSearch,
    } = props;

    const totalPrice = editRows.map( x => x.price ).reduce( (acc, val) => {
        return acc + val
    }, 0)

	const editButtons = (
		<React.Fragment>
			<Tr>
				<Td colSpan={13}>
					<button
						onClick={() => {
							purchaseOrderControls.addNewRow(purchaseBeingEdited || -1);
						}}
					>
						+ Add Product
					</button>
					&nbsp;&nbsp;
					<button
						onClick={() => {
							purchaseOrderControls.addNewRow(purchaseBeingEdited || -1, enumOrderLineType.discount);
						}}
					>
						+ Add Discount
					</button>
					&nbsp;&nbsp;
					<button
						onClick={() => {
							purchaseOrderControls.addNewRow(purchaseBeingEdited || -1, enumOrderLineType.shipping);
						}}
					>
						+ Add Shipping
					</button>
					&nbsp;&nbsp;
					<button
						onClick={() => {
							purchaseOrderControls.addNewRow(purchaseBeingEdited || -1, enumOrderLineType.shippingExtras);
						}}
					>
						+ Add Shipping Extras
					</button>
				</Td>
			</Tr>
			<Tr>
				<Td colSpan={9}>
					<button
						onClick={() => {
							purchaseOrderControls.savePurchaseOrder();
						}}
					>
						Save Purchase Order
					</button>
					&nbsp;&nbsp;
					<button
						onClick={() => {
							purchaseOrderControls.cancelPurchaseOrder();
						}}
					>
						Cancel
					</button>
					&nbsp;&nbsp;
					<button
						onClick={() => {
							//purchaseOrderControls.deletePurchaseOrder(editRows[0].purchaseId);
						}}>
						Delete Purchase Order
					</button>
					<button
						onClick={() => {
							purchaseOrderControls.doBookkeeping(editRows[0].purchaseId);
						}}>
						Do Bookkeeping
					</button>
				</Td>
                <Td>{totalPrice/100}</Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
			</Tr>
		</React.Fragment>
	);
	return (
		<React.Fragment>
			{editRows.map((editRow, i) => {
				console.log({ editRow123: editRow });
				return (
					<EditRow
                        orderLineControls={orderLineControls}
						// deleteEditRow={deleteEditRow}
						// setSugBoxEditRowLinkIdx={setSugBoxEditRowLinkIdx}
						key={i}
						editRowIdx={i}
						editRow={editRow}
						handlerFieldChange={handlerFieldChange}
						setShowProdSearch={setShowProdSearch}
					/>
				);
			})}
			{editRows.length === 0 ? "" : editButtons}
		</React.Fragment>
	);
}
