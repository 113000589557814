import Database, { ISupplierPurchaseLine, EnumOrderProductStatus, EnumStatus } from '../../firestore'
import { IProdSupplier } from './index'
import { enumOrderLineType } from './../shared'

export function purchaseAddRow( props: { purchaseArr: ISupplierPurchaseLine[], purchaseId?: number, initStatus?: EnumStatus, shippingType?: enumOrderLineType }) {
    const { purchaseArr, purchaseId, initStatus, shippingType } = props
    const { internalSku, supplierSku, productName, supplierId } = (() => {
        if(shippingType === enumOrderLineType.discount) {
            return {
                internalSku: enumOrderLineType.discount,
                supplierSku: '-',
                productName: 'Purchase Discount',
                supplierId: -2,
            }
        }
        if(shippingType === enumOrderLineType.shipping) {
            return {
                internalSku: enumOrderLineType.shipping,
                supplierSku: '-',
                productName: 'Purchase Shipping',
                supplierId: -2,
            }
        }
        if(shippingType === enumOrderLineType.shippingExtras) {
            return {
                internalSku: enumOrderLineType.shippingExtras,
                supplierSku: '-',
                productName: 'Purchase Shipping Extra',
                supplierId: -2,
            }
        }
        return {
            internalSku: '',
            supplierSku: '',
            productName: '',
            supplierId: -1,
        }
    })();
    return [ ...purchaseArr,  {
        purchaseId: purchaseId ? purchaseId: -1,
        supplierId,
        insertTime: -1,
        receivedTime: -1,
        internalSku,
        supplierSku,
        productName,
        qty: 1,
        price: 0,
        status: initStatus || EnumStatus.na,
        // inStock: boolean,
        sentTime: undefined,
    }]
}

export function purchaseDeleteRow( props: { editRowsArr: ISupplierPurchaseLine[], deleteIdx: number }) {
    const { editRowsArr, deleteIdx } = props
    return [ ...editRowsArr.slice(0, deleteIdx), ...editRowsArr.slice(deleteIdx + 1, editRowsArr.length)]
}


export function purchaseSelectSuggestion( props: { activeEditRowIndex: number, editRowsArr: ISupplierPurchaseLine[], selectedItem: IProdSupplier }) {
    const { activeEditRowIndex, editRowsArr, selectedItem } = props
    // const activeEditRowIndex = purchaseArr.findIndex( x => x.isActive === true )
    // if( activeEditRowIndex === -1 ) return
    
    const activeRow = editRowsArr[activeEditRowIndex]
    // console.log({editRowsArr, activeEditRowIndex})
    // console.log({activeRow, aa: {... activeRow,a:1}})
    editRowsArr[activeEditRowIndex] = {
        ...activeRow,
        supplierId: selectedItem.supplierId,
        internalSku: selectedItem.sku,
        supplierSku: selectedItem.supplierSku,
        productName: selectedItem.name,
        price: selectedItem.price,
    }
    return editRowsArr
}


// export interface ISupplierPurchase {
//     purchaseId: number
//     supplierId: number,
//     insertTime: number,
//     receivedTime?: number
//     internalSku: string,
//     supplierSku: string,
//     productName: string,
//     qty: number,
//     price: number,
//     status: EnumStatus,
//     // inStock: boolean,
//     sentTime?: number,
//     isActive?: boolean
//   }
