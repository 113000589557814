import React, { useState, useEffect } from 'react'
import {
	Table,
	TrHead,
	Tr,
	Th,
	Td,
	TableControls,
	TableControlItem,
	ControlLabel,
	BtnStatus,
} from "../table.style";
import { enumSalesType } from "../order-component";
import { enumOrderLineType } from "../shared";

export function addThRow( cols: any[], rightAlignCols: number[] ) {
    return <Tr>
        { cols.map( ( col, i) => {
            return <Td rightAlign={rightAlignCols.indexOf(i) > -1}>{col}</Td>
        } )}
    </Tr>
}
export function addTdRow( cols: any[], rightAlignCols: number[] ) {
    return <Tr>
        { cols.map( ( col, i) => {
            return <Td rightAlign={rightAlignCols.indexOf(i) > -1}>{col}</Td>
        } )}
    </Tr>
}



export function calculateVatAndTotals(orderItems: IOrderItemWithVat[], dkVat: boolean) {
    const amountArray = orderItems.map( x => x.amount )
    const vatArray = orderItems.map( x => x.vat )

    let totalAmountExVat = amountArray.reduce( ( acc, val ) => acc + val, 0)
    let totalVatFromTotal = dkVat ? Math.round( totalAmountExVat / 4) : 0
    let totalVatSumItems = dkVat ? vatArray.reduce( ( acc, val ) => acc + val, 0) : 0

    return {
        totalAmountExVat,
        totalVatFromTotal,
        totalVatSumItems,
    }
}

export interface IOrderItem {
    skuInternal: string,
    name: string,
    amount: number,
    qty: number,
    type: enumSalesType | enumOrderLineType
}

export interface IOrderItemWithVat extends IOrderItem {
    vat: number,
}

export enum enumBookkeepingType {
    purchaseOrder = "purchaseOrder",
    webshopOrder = "webshopOrder",
}
export interface IBookeepingApiMsg {
    isSale: boolean,
    purchaseSpecific?: IBookeepingApiMsgIPurchase
    internalVoucherId: number,
    orderNumber?: string,
    invoiceDate: string,
    items: IBookeepingApiMsgItem[],
}

export interface IBookeepingApiMsgItem {
    text: string,
    amount: number,
    qty: number,
    type: enumSalesType | enumOrderLineType
}

export interface IBookeepingApiMsgIPurchase {
    supplierId: number,
    supplierVoucherId: string,
    paymentDate: string,
}

export async function sendBookkeepingData( entries: IBookeepingApiMsg, cbSuccess: () => void ) {
    await postData('https://europe-west1-p2bike-internal.cloudfunctions.net/adminApiBookkeeping', { data: entries }, cbSuccess )
    // await postData('http://localhost:3001/adminApiBookkeeping', { data: entries }, cbSuccess )
}

async function postData(url: string, data: any, cbSuccess: () => void) {
    let formBody: any = [];
    for (let property in data) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(JSON.stringify(data[property]));
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    
    const response = await fetch(url, {
      method: 'POST', 
      //mode: 'no-cors', 
      //cache: 'no-cache', 
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: formBody
    });
    const responseBody = await response.text()
    const res: any = JSON.parse(responseBody)
    if(res.status === "ok") cbSuccess();
    return true
}
