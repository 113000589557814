import React, { useState, useEffect } from 'react'
import { getOrders, IOrder } from './../../api'
import { Table, TrHead, Tr, Th, Td, TableControls, TableControlItem, ControlLabel, BtnStatus } from '../table.style'
import Database, { IOrderExtension, EnumOrderProductStatus, IReservedProducts, IRewriteProduct } from '../../firestore'
import { BookkeepingOrder, enumBookkeepingType } from '../bookkeeping-component'
import Modal from '../utilities/modal'
import { missingSkus, replaceEntries } from './fix-woo-sku'
import { IOrderP2b } from '../../api/admin-api.models'
import { IUnreservedProduct } from '../../App'

enum StatusToShow {
    all = "all",
    completed = "completed",
    refunded = "refunded",
    processing = "processing"
}

export enum enumSalesType {
    soldProduct = 'soldProduct',
    clientShipping = 'clientShipping',
    productConsumption = 'productConsumption',
    afrunding = 'afrunding'
}

export default function OrderComponent( props: { db: Database, isSignedIn: boolean, orderExtensionArray: IOrderExtension[], reservedProducts: IReservedProducts[], unreservedProducts: IUnreservedProduct[], rewrittenProducts: IRewriteProduct[]} ) {
    const { db, isSignedIn, orderExtensionArray, reservedProducts, unreservedProducts, rewrittenProducts } = props
    
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [showStatus, setShowStatus] = useState<StatusToShow>(StatusToShow.completed);
    const [hasInitOrderExt, setInitedOrderExt] = useState<boolean>(false);
    const [modal, setModal] = useState<JSX.Element | undefined>(undefined)
    const [bookkeepingInvoiceId, setBookkeepingInvoiceId] = useState<number | undefined>(undefined)

    useEffect( () => {
        if(orders.length === 0 && isSignedIn) {
            getOrders().then( val => {
                setOrders( skuFix(val) );
            })
        }
    }, [isSignedIn])
    // useEffect( () => {
    //     if( modalInvId !== undefined ) doBookkeeping(modalInvId)
    // }, [reservedProducts, unreservedProducts])

    useEffect( () => {
        const unsubIntv = setInterval( () => {
            if( isSignedIn && orders.length > 0 && orderExtensionArray.length > 0 && !hasInitOrderExt ) {
                const ordersToCreate = orders.filter( order => !orderExtensionArray.some( orderExt => orderExt.id === order.orderNumber)).map( x => x.orderNumber )
                db.initOrderExtension( ordersToCreate )
                clearInterval( unsubIntv )
                setInitedOrderExt( true )
            }
            if( hasInitOrderExt ) clearInterval( unsubIntv )
        }, 500)
        return () => {
            clearInterval( unsubIntv )
        }
    })
    
    const orderItems = orders
    useEffect( () => {
        if( bookkeepingInvoiceId == undefined ) return;

        const order = orderItems.find( x => parseInt( x.invoiceNumber || "-999" ) === bookkeepingInvoiceId )
        if( order === undefined ) return;

        const bookKeepingOrderItems = order.products.map( prod => {
            return {
                skuInternal: prod.sku,
                name: prod.name,
                amount: Math.round(100 * parseFloat( prod.price )),
                qty: prod.quantity,
                type: enumSalesType.soldProduct
            }
        });
        
        (function addShipping() {
            const shipping = {
                skuInternal: 'client-shipping',
                name: 'Client Shipping',
                amount: Math.round(100 * order.shippingPrice),
                qty: 1,
                type: enumSalesType.clientShipping
            }
            if(order.shippingPrice > 0) bookKeepingOrderItems.push(shipping)
        }());

        
        const totalPaid = Math.round( parseFloat(order.priceTotal) * 100 )
        const totalPaidVat = Math.round( parseFloat(order.priceVAT) * 100 )
        const invoiceDate = ( () => {
            if( order.invoiceDate === null || order.invoiceDate === undefined ) return null
            return order.invoiceDate.split('T')[0]
        } )();

        const nextPrevBookkeeping = ( prev: boolean ) => {
            setModal(undefined)
            if(prev) {
                setTimeout( () => {setBookkeepingInvoiceId( bookkeepingInvoiceId - 1 )} , 100) 
            } else {
                setTimeout( () => {setBookkeepingInvoiceId( bookkeepingInvoiceId + 1 )} , 100) 
            }
        }
        setModal(
            <Modal mobileView={false} onClose={ () => { setModal(undefined) }} height={800} width={1200}>
                <BookkeepingOrder 
                    orderNumber={order.orderNumber}
                    bilagsnummer={bookkeepingInvoiceId}
                    orderItems={bookKeepingOrderItems}
                    invoiceDate={invoiceDate || ""}
                    totalPaid={totalPaid}
                    totalPaidVat={totalPaidVat}
                    unreservedProducts={unreservedProducts}
                    reservedProducts={reservedProducts}
                    db={db}
                    cbNextPrevEntry={nextPrevBookkeeping}
                    rewrittenProducts={rewrittenProducts}
                />
            </Modal>
        )
    }, [bookkeepingInvoiceId, unreservedProducts, reservedProducts])


    

    const outOrderItems = orderItems.filter( item => {
        const itemStatus = item.status + ''
        if( showStatus === "all" ) return true
        if( showStatus === StatusToShow.completed && ( itemStatus === StatusToShow.completed || itemStatus === StatusToShow.refunded ) ) return true
        if( itemStatus === showStatus) return true
        return false;
    })
    .sort( (a, b ) => {
        if( showStatus === StatusToShow.completed ) return parseInt(a.invoiceNumber || '0') < parseInt(b.invoiceNumber || '0') ? 1 : -1
        return a.orderNumber < b.orderNumber ? 1 : -1
    })
    .map( order => {
        const extData = getOrderExtData( orderExtensionArray, order.orderNumber )
        
        const toggleExtData = ( orderNumber: number, fieldName: string ) => {
            // @ts-ignore
            db.setOrderExtension( { ...extData, [fieldName]: !extData[fieldName] } )
        }
        
        const transactionCaptureAlert = order.status === "completed" && order.dibsTransactionCaptured !== true
        const noProducts = order.products.map( x => x.quantity ).reduce( (acc, val) => acc + val, 0)

        const numericOrderNo = parseInt(order.invoiceNumber || "-1")
        const noResProducts = ( () => {
            const finding = reservedProducts.find( x => x.shopInvoiceId === numericOrderNo )
            if( finding === undefined ) return 0
            return finding.items.length
        })()
        
        return (
            <Tr key={order.orderNumber}>
                <Td>{order.status}</Td>
                <Td>{order.invoiceDate || ""}</Td>
                <Td><a href={`https://power2bike.com/wp-admin/post.php?post=${order.orderNumber}&action=edit`} target="_blank" rel="noreferrer">{order.orderNumber}</a>{!Boolean(order.customerNote) ? '': <div title={order.customerNote}>Note</div>}</Td>
                <Td>{order.invoiceNumber || ""}</Td>
                <Td>{order.transactionId || ""}</Td>
                <Td>{order.billing.first_name + " " + order.billing.last_name}</Td>
                <Td>{order.shipping.city}</Td>
                <Td>{order.priceTotal}</Td> 
                <Td bgColor={transactionCaptureAlert ? "#ff0000" : undefined}>{transactionCaptureAlert ? "Alert!" : "" }</Td>

                <Td clickable={true} onClick={ () => { toggleExtData( order.orderNumber, "checkMoneyReserved" ) } }>{extData.checkMoneyReserved ? 1 : 0}</Td>
                <Td clickable={true} onClick={ () => { toggleExtData( order.orderNumber, "checkMoneyCharged" ) } }>{extData.checkMoneyCharged ? 1 : 0}</Td>
                <Td clickable={true} onClick={ () => { toggleExtData( order.orderNumber, "checkMoneyReceived" ) } }>{extData.checkMoneyReceived ? 1 : 0}</Td>
                <Td clickable={true} onClick={ () => { toggleExtData( order.orderNumber, "checkPackageSent" ) } }>{extData.checkPackageSent ? 1 : 0}</Td>
                <Td clickable={true} onClick={ () => { toggleExtData( order.orderNumber, "checkTrackMail" ) } }>{extData.checkTrackMail ? 1 : 0}</Td>
                <Td>{extData.productStatus}</Td>
                <Td bgColor={noResProducts !== noProducts ? "Yellow" : undefined}>{`${noResProducts}/${noProducts}`}</Td>
                <Td bgColor={extData.checkBookkept ? "#00ff00": "White"}><button onClick={ () => { setBookkeepingInvoiceId( numericOrderNo ) } }>Bookkeeping</button></Td>
            </Tr>
        )
        
        // checkMoneyReserved: false,
        // checkMoneyCharged: false,
        // checkMoneyReceived: false,
        // checkPackageSent: false,
        // checkTrackMail: false,
        // productStatus: EnumOrderProductStatus.NA
    })

    return (
        <div>
            <TableControls>
                <TableControlItem>
                    <ControlLabel>Status Filter</ControlLabel>
                    <BtnStatus highlight={ showStatus === StatusToShow.processing } onClick={()=>{ setShowStatus( StatusToShow.processing ) }}>Processing</BtnStatus>
                    <BtnStatus highlight={ showStatus === StatusToShow.completed } onClick={()=>{ setShowStatus( StatusToShow.completed ) }}>Completed</BtnStatus>
                    <BtnStatus highlight={ showStatus === StatusToShow.all } onClick={()=>{ setShowStatus( StatusToShow.all ) }}>All</BtnStatus>
                </TableControlItem>
            </TableControls>
            {modal}
            <Table>
                <thead>
                    <TrHead>
                        <Th>Status</Th>
                        <Th>Inv Date</Th>
                        <Th>Order No</Th>
                        <Th>Inv No</Th>
                        <Th>Tra No</Th>
                        <Th>Name</Th>
                        <Th>Shipping</Th>
                        <Th>Total Price</Th>
                        <Th>Dibs</Th>
                        
                        
                        <Th>MReserved</Th>
                        <Th>MCharged</Th>
                        <Th>MReceived</Th>
                        <Th>extData.checkPackageSent</Th>
                        <Th>extData.checkTrackMail</Th>
                        <Th>extData.productStatus</Th>
                        <Th>Reserved</Th>
                        <Th>Bookkeeping</Th>
                    </TrHead>
                </thead>
                <tbody>
                    { outOrderItems }
                </tbody>
            </Table>
        </div>
    )
}

function getOrderExtData( orderExtArray: IOrderExtension[], targetId: number ): IOrderExtension {
    const extData = orderExtArray.find( orderExt => orderExt.id === targetId )
    if( extData === undefined ) return {
        id: -1,
        checkMoneyReserved: false,
        checkMoneyCharged: false,
        checkMoneyReceived: false,
        checkPackageSent: false,
        checkTrackMail: false,
        checkBookkept: false,
        productStatus: EnumOrderProductStatus.NA
    }

    return extData
}



function skuFix( val: IOrderP2b[] ) {
    return skuFixReplacables(
        skuFixMissing(val)
        )
}

function skuFixMissing( val: IOrderP2b[] ) {
    const output: IOrderP2b[] = []
    for( const order of val ) {
        
        const outProducts = order.products.map( x => {
            const needle = missingSkus.find( y => (y.force === true || x.sku === null) && y.text === x.name )
            if( needle === undefined ) return x
            return {
                ...x,
                sku: needle.sku
            }
        })
        output.push({
            ...order,
            products: outProducts
        })
    }
    return output
}


function skuFixReplacables( val: IOrderP2b[] ) {
    const output: IOrderP2b[] = []
    for( const order of val ) {
        
        const outProducts = order.products.map( x => {
            const entryToReplace = replaceEntries.find( repl => repl.from.sku === x.sku && repl.from.invoiceId === (order.invoiceNumber || '') )
            if( entryToReplace === undefined ) {
                return x
            } else {
                return {
                    ...x,
                    sku: entryToReplace.to.sku
                }
            }
        })
        output.push({
            ...order,
            products: outProducts
        })
    }
    return output
}
