import React, { useState, useEffect } from 'react'
import {
	Table,
	TrHead,
	Tr,
	Th,
	Td,
	TableControls,
	TableControlItem,
	ControlLabel,
	BtnStatus,
} from "../table.style";
import { enumSalesType } from "../order-component";
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import { TdInputTxt, Section, InnerSection, ModalWrapper, PrevEntryBtn, NextEntryBtn } from './index.style'
import { twoDigitString, twoDigitNoTrailingZero } from '../../helpers'
import { addThRow, addTdRow, calculateVatAndTotals, IOrderItem, IOrderItemWithVat, sendBookkeepingData, IBookeepingApiMsg, IBookeepingApiMsgItem } from './shared'
import { IUnreservedProduct } from './../../App'
import Database, { IReservedProducts, IRewriteProduct } from './../../firestore'
import RewriteProduct from './rewrite-product';

export default function BookkeepingOrder( props: IBookkeepingOrderEntry ) {
    const {
        orderNumber,
        bilagsnummer: invoiceNumber,
        invoiceDate,
        totalPaid: paymentTotalAmount,
        totalPaidVat: paymentVatAmount,
        db,
        reservedProducts,
        cbNextPrevEntry,
        rewrittenProducts,
    } = props
    const localUnreservedProducts = [...props.unreservedProducts]

    const applyDkVat = true

    const [ vatRoundingAmount, setVatRoundingAmount ] = useState<number>( 0 )
    const [ showRewriteProduct, setShowRewriteProduct ] = useState<boolean>(false);
    const [ orderItemsWithVat, setOrderItemsWithVat ] = useState<IOrderItemWithVat[]>( props.orderItems.map( x => {
        const foundRewriteProduct = rewrittenProducts.find(y => (y.invoiceNumber === String(invoiceNumber) && y.itemName === x.name))
        const skuInternal = foundRewriteProduct ? foundRewriteProduct.newSku : x.skuInternal
        return {
            ...x,
            skuInternal,
            vat: applyDkVat === true ? Math.round( x.amount / 4) : 0
        };
    }));

    const {
        totalAmountExVat,
        totalVatFromTotal,
        totalVatSumItems,
    } = calculateVatAndTotals( orderItemsWithVat, applyDkVat )

    const reserveProduct = ( shopInvoiceId: number, purchaseId: number, sku: string ) => {
        db.addReservedProduct( shopInvoiceId, purchaseId, sku  )
    }

    useEffect( () => {
        const vatToAdd = totalVatFromTotal - totalVatSumItems
        if( vatToAdd !== 0) setOrderItemsWithVat(
            [...orderItemsWithVat, {
            skuInternal: "dk-vat-rounding",
            name: "Afrunding Moms",
            amount: 0,
            qty: 1,
            vat:  vatToAdd,
            type: enumSalesType.afrunding
        }] )

        const keyHandler = (ev: KeyboardEvent) => {
            const name = ev.key;
            if(name === 'q') {
                cbNextPrevEntry(true)
            }
            if(name === 'w') {
                cbNextPrevEntry(false)
            }
        };
        document.addEventListener("keydown", keyHandler);
        return function cleanup() {
            document.removeEventListener("keydown", keyHandler);
        };
    }, []);


    (function addConsumedProducts(){
        let accumulatedReservedProductsPrice = ( () => {
            let acc = 0
            orderItemsWithVat.forEach( (item, i) => {
                const { reservedProductsPrice } = getIfReservedAndConsumptionPrice( invoiceNumber, item.skuInternal, item.qty, reservedProducts )
                acc += reservedProductsPrice
            });
            return acc
        } )();
        
        const shouldUpdate = (()=>{
            const existingVareforbrug = orderItemsWithVat.find(x => x.name === 'Vareforbrug');
            if(existingVareforbrug === undefined) {
                return true;
            }
            return existingVareforbrug.amount !== accumulatedReservedProductsPrice;
        })();

        if(shouldUpdate) {
            setOrderItemsWithVat([...orderItemsWithVat.filter(x => x.name !== "Vareforbrug"), {
                    skuInternal: enumSalesType.productConsumption,
                    name: "Vareforbrug",
                    amount: accumulatedReservedProductsPrice,
                    qty: 1,
                    vat: 0,
                    type: enumSalesType.productConsumption
                }
            ]);
        }
    })();

    const bookkeepingDataItems: IBookeepingApiMsgItem[] = []
    const orderItemData = orderItemsWithVat.map( (item, i) => {
        const { isReserved, reservedProductsPrice } = getIfReservedAndConsumptionPrice( invoiceNumber, item.skuInternal, item.qty, reservedProducts )

        const foundUnresIdx = localUnreservedProducts.findIndex( x => x.sku === item.skuInternal )
        const existsUnreserved = foundUnresIdx > -1
        const foundRes = localUnreservedProducts[foundUnresIdx]
        // localUnreservedProducts.splice(foundUnresIdx, 1);


        const isProduct = item.type === enumSalesType.soldProduct

        const invoiceLine = ( () => {
            switch(item.type) {
                case enumSalesType.soldProduct:
                    return `Varesalg - ${item.qty}x - ${item.skuInternal}`;
                case enumSalesType.clientShipping:
                    return `Salg forsendelse`;
                case enumSalesType.productConsumption:
                    return `Vareforbrug`;
                case enumSalesType.afrunding:
                    return `Afrunding`;
            }
            return "Unknown"
        } )();

        const reservationOutput = ( () => {
            if(!isProduct) return ""
            if(isReserved) return "Reserved"
            if(!existsUnreserved) return (<div>
                <div>None to reserve</div>
                { !showRewriteProduct ? <button onClick={() => setShowRewriteProduct(true)}>Create Custom</button> : ''}
                { showRewriteProduct ? <RewriteProduct db={db} invoiceNumber={String(invoiceNumber)} itemName={item.name} unreservedProducts={localUnreservedProducts} /> :''}
            </div>)
            return <button onClick={() => reserveProduct( invoiceNumber, foundRes.purchaseId, foundRes.sku )}>{foundRes.purchaseId + ' - ' + foundRes.sku}</button>
        } )();

        bookkeepingDataItems.push({
            text: invoiceLine,
            amount: item.amount,
            qty: item.qty,
            type: item.type
        })
        return <Tr>
            <Td>{item.name}</Td>
            <Td>{invoiceLine}</Td>
            <Td>{item.type}</Td>
            <Td>{item.qty}</Td>
            <Td rightAlign={true}>{twoDigitString(item.amount)}</Td>
            <Td rightAlign={true}>{twoDigitString(item.vat)}</Td>
            <Td rightAlign={true}>{isProduct ? twoDigitString(item.amount - reservedProductsPrice) :""}</Td>
            <Td>{reservationOutput}</Td>
        </Tr>
    })
    
    const bookkeepingData: IBookeepingApiMsg = {
        isSale: true,
        internalVoucherId: invoiceNumber,
        orderNumber: String(orderNumber),
        invoiceDate: invoiceDate,
        items: bookkeepingDataItems
    }

    return (
        <ModalWrapper>
            <PrevEntryBtn onClick={ () => { cbNextPrevEntry(true) }}>Previous</PrevEntryBtn>
            <NextEntryBtn onClick={ () => { cbNextPrevEntry(false) }}>Next</NextEntryBtn>
            <Section>
                <InnerSection>
                    <Table>
                        <thead>
                        </thead>
                        <tbody>
                            { addTdRow(["Regnskabsår", "2023/2024"], [])}
                            { addTdRow(["Bilagsnummer (intern)", invoiceNumber], [])}
                            { addTdRow(["Ordernummer", orderNumber], [])}
                        </tbody>
                    </Table>
                </InnerSection>
                <InnerSection>
                    <Table>
                        <thead>
                        </thead>
                        <tbody>
                            { addTdRow(["Fakturadato", invoiceDate + ''], []) }
                            { addTdRow(["Total betalingsbeløb", paymentTotalAmount+ ''], []) }
                            { applyDkVat ? addTdRow(["Total momsbeløb", paymentVatAmount + ''], []) : ""}
                            { applyDkVat ? addTdRow(["", <button onClick={ () => { if(bookkeepingData) sendBookkeepingData(bookkeepingData, () => { db.setOrderBookkept( orderNumber ) }) }} disabled={bookkeepingData === undefined}>BOGFØR!</button>], []) : ""}
                        </tbody>
                    </Table>
                </InnerSection>
            </Section>
            <Section>
                <Table>
                        <thead>
                            {addThRow(["Product Name","Invoice Line","Type","Qty","Amount","Vat","Earned","Reserve"], [])}
                        </thead>
                        <tbody>
                            {orderItemData}
                            {addTdRow(["TOTAL (SUM)","","","",twoDigitString(totalAmountExVat),twoDigitString(totalVatSumItems),"",""], [4, 5])}
                            {addTdRow(["TOTAL (SIMULATION)","","","",twoDigitString(totalAmountExVat),twoDigitString(totalVatFromTotal),"",""], [4, 5])}
                            {addTdRow(["TOTAL (INDTASTET)","","","",twoDigitString(paymentTotalAmount - paymentVatAmount),twoDigitString(paymentVatAmount),"",""], [4, 5])}
                        </tbody>
                </Table>
            </Section>
        </ModalWrapper>
    )
}

export interface IBookkeepingOrderEntry {
    orderNumber: number,
    bilagsnummer: number,
    orderItems: IOrderItem[],
    invoiceDate: string,
    totalPaid: number,
    totalPaidVat: number,
    unreservedProducts: IUnreservedProduct[],
    reservedProducts: IReservedProducts[]
    db: Database,
    cbNextPrevEntry: (isPrev: boolean) => void,
    rewrittenProducts: IRewriteProduct[]
}

function getIfReservedAndConsumptionPrice(itemInvoiceNumber: number, itemSku: string, itemQty: number, reservedProducts: IReservedProducts[]) {
    return ( () => {
        const resProdThisOrder = reservedProducts.find( x => x.shopInvoiceId === itemInvoiceNumber)
        if(resProdThisOrder === undefined) return { isReserved: false, reservedProductsPrice: 0 };

        const lineReservedProds = resProdThisOrder.items.filter( x => itemSku === x.sku)
        const qtyReserved = lineReservedProds.length
        if( qtyReserved >= itemQty ) return {
            isReserved: true,
            reservedProductsPrice: lineReservedProds.map( x => (x.unitPrice || 0) ).reduce( ( acc, val ) => acc + val, 0)
        }
        return { isReserved: false, reservedProductsPrice: 0 }
    })()
}