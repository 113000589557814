import styled from 'styled-components'

export const Logo = styled.img`
    height: 38px;
    margin: 0px;
    position: absolute;
    top: 6px;
    left: 20px;
`
export const HeaderWrapper = styled.div`
    height: 48px;
    margin: -8px 0 0 -8px;
    background-color: #00554e;
    border-bottom: 1px solid #00554e;
    box-shadow: 3px 3px 5px 0px rgba(163,163,163,1);
    width: calc(100% + 16px);
    position: relative;
    padding: 0;
    text-align: center;
`
export const MenuItems = styled.ul`
    position: absolute;
    top: -10px;
    left: 200px;
`
export const MenuItem = styled.li`
    margin: 20px;
    margin-top: 4px;
    display: inline-block;
    font-family: 'Roboto';
    text-size: 14px;
    font-weight: 500;
    color:white;
    cursor: pointer;
`
export const MenuItemLink = styled.a`
    font-family: 'Roboto';
    text-size: 14px;
    font-weight: 500;
    color:white;
`
export const LoginArea = styled.div<{ hidden: boolean}>`
    display: ${ props => props.hidden ? "none;": "block;" }
    position: absolute;
    top: 16px;
    right: 200px;
`
export const SignedInAs = styled.div<{ hidden: boolean}>`
    display: ${ props => props.hidden ? "none;": "block;" }
    position: absolute;
    top: 16px;
    right: 200px;
    font-family: 'Roboto';
    text-size: 14px;
    color: white;
    cursor: pointer;
`
export const User = styled.div`
    display: inline-block;
    width: 100px;
    margin-right: 8px;
`
export const Pass = styled.div`
    display: inline-block;
    width: 100px;
    margin-right: 8px;
`
export const Submit = styled.div`
    display: inline-block;
    width: 50px;
`
export const InputUser = styled.input`
    width: 100%;
`
export const InputPass = styled.input`
    width: 100%;
`
export const SubmitButton = styled.button`
    width: 100%;
`
