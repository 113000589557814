import styled from 'styled-components'

export const ContentArea = styled.div`
width: 100%;
max-width: 1400px;
float: center;
margin-left: auto;
margin-right: auto;
height: 100vh;
padding-top: 48px;
`