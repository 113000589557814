import styled from 'styled-components'
export const TdInputTxt = styled.input`
width: 100%;
height: 20px;
::-webkit-inner-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
}
::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
}   
`;

export const Section = styled.div`
    display: inline-block;
    vertical-align:top;
    width: 100%;
    max-width: 1200px;
`
export const InnerSection = styled.div`
    display: inline-block;
    vertical-align:top;
    margin-left: 8px;
    margin-right: 8px;
`
export const ModalWrapper = styled.div`
    position: relative;
`
export const PrevEntryBtn = styled.button`
    top:20px;
    left:20px;
`
export const NextEntryBtn = styled.button`
    top:20px;
    right:20px;
`
