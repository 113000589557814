import styled from 'styled-components'

export const ComponentWrapper = styled.div<{hide: boolean}>`
    display: ${props => props.hide ? 'none' : 'block'};
`
export const Link = styled.a`
`

export const ChangeTable = styled.table`
    width: '100%';
    border-collapse: collapse;
`;
export const ChangeTableTh = styled.th<{alignRight?: boolean}>`
    background-color: #efefef;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    ${props => props.alignRight === true ? 'text-align: right;': ''}
`;
export const ChangeTableTd = styled.td<{alignRight?: boolean}>`
    padding: 5px;
    ${props => props.alignRight === true ? 'text-align: right;': ''}
`;