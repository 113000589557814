import React from 'react'
import { Table, Tr, TrHead, Th, Td } from '../table.style'
import Database, { ILoggerEntry, ILoggerSource, LoggerTypes, ILoggerErrorEntry, ILoggerHeartbeatEntry } from '../../firestore'
import { ErrorMessage, RotatedText, Small } from './index.style'

const months: { [index: string]: string } = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
}
export default function MonitoringComponent( props: { db: Database, isSignedIn: boolean,  loggerData: ILoggerEntry[], loggerSources: ILoggerSource[] } ) {
    const { db, isSignedIn, loggerData, loggerSources } = props
    const errors = loggerData.filter(row => row.type === LoggerTypes.error) as ILoggerErrorEntry[]
    const heartBeats = loggerData.filter(row => row.type === LoggerTypes.heartbeat) as ILoggerHeartbeatEntry[]
    const heartBeatSourceList = loggerSources.filter(row => row.type.indexOf(LoggerTypes.heartbeat) > -1).map(row => ({ name: row.name, isInSourceList: true }))
    Array.from(new Set(heartBeats.map(row => row.source))).forEach(source => {
        if(!heartBeatSourceList.some(entry => entry.name === source)) heartBeatSourceList.push({ name: source, isInSourceList: false})
    })
    heartBeatSourceList.sort((a, b) => a > b ? 1 : -1)

    const dateArray: string[] = []
    for( let day = 0; day < 30; day++ ) {
        dateArray.push(new Date(Date.now() - day * 60 * 60 * 24 * 1e3).toISOString().substr(0, 10))
    }

    const heartBeatRows: HeartbeartRow[] = heartBeatSourceList.map(row => {
        const dataPoints = dateArray.map(date => {
            const foundDate = heartBeats.find(data => data.date === date && data.source === row.name)?.insertTimestamp.seconds
            if(foundDate === undefined) return undefined;
            return new Date(foundDate * 1e3).toISOString().replace(/.*T/g, '').split(':').slice(0, 2).join(':')
        })
        return {
            ...row,
            dataPoints
        }
    })

    return (
        <div>
            <h1>Heartbeats</h1>
            <Table>
                <thead>
                    <TrHead>
                        <Th></Th>
                        { dateArray.map(date => <Th key={date}><RotatedText>{date.split('-')[2] + ' ' + months[date.split('-')[1]]}</RotatedText></Th>)}
                    </TrHead>
                </thead>
                <tbody>
                {
                    heartBeatRows.map(row => 
                    (<Tr key={row.name}>
                        <Td bgColor={row.isInSourceList ? '#fff' : 'yellow'} clickable={!row.isInSourceList} onDoubleClick={() => {
                            db.setLoggerSource({
                                name: row.name,
                                type: [LoggerTypes.heartbeat, LoggerTypes.error],
                            })
                        }}>{row.name}</Td>
                        {
                            row.dataPoints.map((column, i) => <Td key={i} bgColor={column === undefined ? '#fff': '#35C764'} paddingLr={0}><Small>{column === undefined ? '﻿' : column}</Small></Td>)
                        }
                    </Tr>))
                }
                </tbody>
            </Table>
            <h1>Errors</h1>
            <Table>
                <thead>
                    <TrHead>
                        <Th>Time</Th>
                        <Th>Source</Th>
                        <Th>Error Message</Th>
                    </TrHead>
                </thead>
                <tbody>
                {
                    errors.sort((a, b) => a.insertTimestamp < b.insertTimestamp ? 1 : -1).map(error => (
                        <Tr key={error.insertTimestamp.seconds + ''}>
                            <Td>{new Date(error.insertTimestamp.seconds * 1e3).toISOString().replace(/\.000Z/g ,'').replace(/T/g ,' ')}</Td>
                            <Td>{error.source}</Td>
                            <Td><ErrorMessage>{error.message}</ErrorMessage></Td>
                        </Tr>
                    ))
                }
                </tbody>
            </Table>
            {loggerData.length}
            {loggerSources.length}
        </div>
    )
}

interface HeartbeartRow {
    name: string
    isInSourceList: boolean
    dataPoints: (string | undefined)[]
}