import React from "react";
import Database, {
	ISupplierPurchaseLine,
	EnumOrderProductStatus,
	EnumStatus,
} from "../../firestore";
import {
	Table,
	TrHead,
	Tr,
	Th,
	Td,
	TableControls,
	TableControlItem,
	ControlLabel,
	BtnStatus,
} from "../table.style";
import { TdInputTxt, TdInputSelect, TdInputOption } from "./index.style";
import { DeleteBtn } from "./edit-row.style";
import { suppliers } from "../shared";
import { IOrderLineControls } from ".";
import { twoDigitString, twoDigitNoTrailingZero } from '../../helpers'

export default function EditRow(props: {
	// deleteEditRow: (idx: number) => void;
    // setSugBoxEditRowLinkIdx: (idx: number) => void;
    orderLineControls: IOrderLineControls;
	editRowIdx: number;
	editRow: ISupplierPurchaseLine;
	handlerFieldChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => void;
	setShowProdSearch: (input: boolean) => void;
}) {
	const {
        orderLineControls,
		editRowIdx,
		editRow,
		handlerFieldChange,
		setShowProdSearch,
    } = props;
    const {
		deleteEditRow,
        setSugBoxEditRowLinkIdx,
    } = orderLineControls
    
    const isNonProduct = editRow.supplierId === -2
    const handleFieldChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value
        if(val[val.length-1] === ',')
        {
        } else {
            handlerFieldChange(event);
        }
        
    }

	return (
		<Tr key={editRowIdx}>
			<Td></Td>
			<Td>
				<DeleteBtn
					onClick={() => {
						deleteEditRow(editRowIdx);
					}}
				>
					Delete
				</DeleteBtn>
			</Td>
			<Td>{editRow.purchaseId}</Td>
			{/* <Td><TdInputTxt type="text" placeholder="Id" /></Td> */}
			<Td>
				<TdInputSelect
                    disabled={isNonProduct}
					data-field-name="supplierId"
					data-edit-row-idx={`${editRowIdx}`}
					onFocus={() => {
						setShowProdSearch(true);
						setSugBoxEditRowLinkIdx(editRowIdx);
					}}
					onChange={handlerFieldChange}
				>
					<TdInputOption
						selected={-1 === editRow.supplierId}
						value="-1"
					>
						-
					</TdInputOption>
					{suppliers.filter( x => x.id > 0 ).map((x) => (
						<TdInputOption
							selected={x.id === editRow.supplierId}
							key={x.id}
							value={x.id}
						>
							{x.name}
						</TdInputOption>
					))}
				</TdInputSelect>
			</Td>
			<Td></Td>
			<Td></Td>
			{/* <Td><TdInputTxt type="number" placeholder="insertTime" /></Td> */}
			{/* <Td><TdInputTxt type="number" placeholder="receivedTime" /></Td> */}
			<Td>
				<TdInputTxt
                    disabled={isNonProduct}
					type="text"
					placeholder="internalSku"
					value={editRow.internalSku}
					data-field-name="internalSku"
					data-edit-row-idx={`${editRowIdx}`}
					onFocus={() => {
						setSugBoxEditRowLinkIdx(editRowIdx);
						setShowProdSearch(true);
					}}
					onBlur={() => {}}
					onChange={handlerFieldChange}
				/>
			</Td>
			<Td>
				<TdInputTxt
                    disabled={isNonProduct}
					type="text"
					placeholder="supplierSku"
					value={editRow.supplierSku}
					data-field-name="supplierSku"
					data-edit-row-idx={`${editRowIdx}`}
					onFocus={() => {
						setSugBoxEditRowLinkIdx(editRowIdx);
						setShowProdSearch(true);
					}}
					onBlur={() => {}}
					onChange={handlerFieldChange}
				/>
			</Td>
			<Td>
				<TdInputTxt
                    disabled={isNonProduct}
					type="text"
					placeholder="productName"
					value={editRow.productName}
					data-field-name="productName"
					data-edit-row-idx={`${editRowIdx}`}
					onFocus={() => {
						setSugBoxEditRowLinkIdx(editRowIdx);
						setShowProdSearch(true);
					}}
					onBlur={() => {}}
					onChange={handlerFieldChange}
				/>
			</Td>
			<Td>
				<TdInputTxt
                    disabled={isNonProduct}
					type="number"
					placeholder="qty"
					value={editRow.qty}
					data-edit-row-idx={`${editRowIdx}`}
					data-field-name="qty"
                    onChange={handlerFieldChange}
                    min={1}
				/>
			</Td>
			<Td>
				<TdInputTxt
					type="number"
					placeholder="price"
					value={twoDigitNoTrailingZero(editRow.price)}
					data-edit-row-idx={`${editRowIdx}`}
                    data-field-name="price"
                    step="0.01"
					onChange={handleFieldChangePrice}
				/>
			</Td>
			{/* <Td>
				<TdInputSelect
					data-field-name="status"
					data-edit-row-idx={`${editRowIdx}`}
					onChange={handlerFieldChange}
				>
					{Object.keys(EnumStatus).map((x) => (
						<TdInputOption selected={x === editRow.status} key={x}>
							{x}
						</TdInputOption>
					))}
				</TdInputSelect>
			</Td> */}
			{/* <Td><TdInputTxt type="text" placeholder="status" /></Td> */}
			{/* <Td><TdInputTxt type="text" placeholder="inStock" /></Td> */}
			{/* <Td></Td> */}
			<Td></Td>
			{/* <Td><TdInputTxt type="text" placeholder="sentTime" /></Td> */}
		</Tr>
	);
}

{
	/* <Td><TdInputTxt type="text" placeholder="internalSku" value={editRow.internalSku} data-fieldName="internalSku" data-editRowIdx={`${editRowIdx}`} onFocus={ () => { setSugBoxEditRowLinkIdx(editRowIdx); setShowProdSearch(true) }} onBlur={ () => { setTimeout( ()=>{setSugBoxEditRowLinkIdx(-1)}, 1000 )}} onChange={handlerFieldChange} /></Td>
<Td><TdInputTxt type="text" placeholder="supplierSku" value={editRow.supplierSku} data-fieldName="supplierSku" data-editRowIdx={`${editRowIdx}`} onFocus={ () => { setSugBoxEditRowLinkIdx(editRowIdx); setShowProdSearch(true) }} onBlur={ () => { setTimeout( ()=>{setSugBoxEditRowLinkIdx(-1)}, 1000 )}} onChange={handlerFieldChange} /></Td>
<Td><TdInputTxt type="text" placeholder="productName" value={editRow.productName} data-fieldName="productName" data-editRowIdx={`${editRowIdx}`} onFocus={ () => { setSugBoxEditRowLinkIdx(editRowIdx); setShowProdSearch(true) }} onBlur={ () => { setTimeout( ()=>{setSugBoxEditRowLinkIdx(-1)}, 1000 )}} onChange={handlerFieldChange} /></Td>
             */
}
