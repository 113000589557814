
export const suppliers: ISupplier[] = [
    { id: -2, name: "-", dkVat: null },
    { id: 1, name: "Promovec", dkVat: true },
    { id: 2, name: "Hartje", dkVat: false },
    { id: 3, name: "Bikepartsde", dkVat: false },
    { id: 4, name: "Twindis", dkVat: false },
    { id: 5, name: "JD", dkVat: false },
    { id: 6, name: "Hollandbikeshop", dkVat: false },
    { id: 7, name: "AkkuZentrum", dkVat: false },
    { id: 8, name: "Diavelo", dkVat: false },
    { id: 9, name: "Shimano", dkVat: true },
    { id: 10, name: "Hartje Ebsen", dkVat: true },
    { id: 11, name: "Akku Vision", dkVat: true },
    { id: 12, name: "Brinckers", dkVat: false }, 
    { id: 13, name: "YosePower.com", dkVat: false },
    { id: 14, name: "BMZ", dkVat: false },
    { id: 15, name: "Lindebjerg", dkVat: true },
    { id: 16, name: "BatteriTilElcykel", dkVat: false },
]
export interface ISupplier {
    id: number,
    name: string,
    dkVat: boolean | null
}


export enum enumOrderLineType {
    product = 'product',
    discount = 'purchase-discount',
    shipping = 'purchase-ship',
    shippingExtras = 'purchase-ship-extra',
    dkVatRounding = 'dk-vat-rounding',
    unk = 'unk',
}