import React, {useState} from 'react'
import Database, { IScrapeSession } from '../../firestore'
import { Table, Tr, TrHead, Th, Td } from '../table.style'
import { Scrollable } from './index.style'
import Modal from '../utilities/modal'

type ISelectedDate = {
    date: string
    supplier: string
}

export default function ScrapedDataComponent( props: { db: Database, isSignedIn: boolean, scrapedData: IScrapeSession[] } ) {
    const { scrapedData } = props
    const [selectedDate, setSelectedDate] = useState<ISelectedDate | undefined>(undefined)
    const modalContents = (()=>{
        if(selectedDate === undefined) return;
        const foundData = scrapedData.find( item => item.supplierName === selectedDate.supplier && item.date === selectedDate.date )
        if(foundData === undefined) return;
        
        return <Scrollable><Table>
            <Tr>
                <Th>Title</Th>
                <Th>StockStatus</Th>
                <Th>Prod Number</Th>
                <Th>Link</Th>
                <Th>Price</Th>
            </Tr>
            {foundData.productArray.map(product => (
                <Tr>
                    <Td>{product.title}</Td>
                    <Td>{product.stockStatus}</Td>
                    <Td>{product.supplierProductNumber}</Td>
                    <Td><a href={product.link}>Link</a></Td>
                    <Td>{product.price}</Td>
                </Tr>
            ))}
        </Table>
        </Scrollable>
    })();

    const daysToView = 7
    
    const dates = new Array(daysToView).fill(0).map((_x, daysBack) => new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() - daysBack + 1)).toISOString().substr(0, 10))
    
    const uniqueSuppliers = Array.from(new Set(scrapedData.map( x => x.supplierName )))
    const tableData = uniqueSuppliers.map( supplier => {
        return <Tr><Td>{supplier}</Td>{dates.map( date => {
            const foundData = scrapedData.find( item => item.supplierName === supplier && item.date === date )
            return foundData ? <Td clickable={true} onClick={() => setSelectedDate({
                date,
                supplier
            })}>{foundData.productArray.length}</Td> : <Td></Td>
        })}</Tr>
    })

    return (
        <div>
            { selectedDate ? <Modal height={600} width={1000} mobileView={false} onClose={()=> setSelectedDate(undefined)}>{modalContents}</Modal> : '' }
            <Table>
                <thead>
                    <Tr>
                        <Th></Th>
                        {dates.map( date => <Th>{date}</Th>)}
                    </Tr>
                </thead>
                <tbody>
                    {tableData}
                </tbody>
            </Table>
        </div>
    )
}
