import styled from 'styled-components'

export const FloatWrapper = styled.div<{ hidden: boolean}>`
    position: fixed;
    bottom:0px;
    right:0px;
    // height: 40vw;
    // overflow-y: scroll;
    background: White;
    display: ${ props => props.hidden ? "none;": "block;"}
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`

const tableBodyHeight = 300;
const columnWidth1 = 200;
const columnWidth2 = 300;
const columnWidth3 = 200;
const columnWidth4 = 200;
const columnWidth5 = 100;
const tableWidth = columnWidth1 + columnWidth2 + columnWidth3 + columnWidth4 + columnWidth5;

export const Tbody = styled.tbody`
`
export const Table = styled.table`
    width: ${tableWidth}px;
    table-layout: fixed;
    border-collapse: collapse;
`
export const ScrollWrapper = styled.div`
    height: 300px;
    overflow-y: scroll;
`
export const TrHead = styled.tr`
    background-color: #01423C;
`
export const Tr = styled.tr`
    background-color: Gray;
`
export const Th = styled.th<{index: number}>`
    color: White;
    background-color: #00554e;
    font-weight: 500;
    border: 1px solid #eee;
    font-family: Roboto,sans-serif;
    box-sizing: border-box;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 56px;
    line-height: 56px;
    padding-right: 16px;
    padding-left: 16px;
    white-space: nowrap;
    
    ${ props => {
        if( props.index === 1 ) {
            return `width: ${columnWidth1}px;`
        }
        if( props.index === 2 ) {
            return `width: ${columnWidth2}px;`
        }
        if( props.index === 3 ) {
            return `width: ${columnWidth3}px;`
        }
        if( props.index === 4 ) {
            return `width: ${columnWidth4}px;`
        }
        if( props.index === 5 ) {
            return `width: ${columnWidth5}px;`
        }
        return ""
    } }

`
export const Td = styled.td<{index: number, bgColor?: string, clickable?: boolean }>`
    color: #424242;
    background-color: ${ props => props.bgColor ? props.bgColor : "#fff" };
    font-weight: 500;
    border: 1px solid #eee;
    font-family: Roboto,sans-serif;
    box-sizing: border-box;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 56px;
    line-height: 56px;
    padding-right: 16px;
    padding-left: 16px;
    white-space: nowrap;
    
    ${ props => {
        if( props.index === 1 ) {
            return `width: ${columnWidth1}px;`
        }
        if( props.index === 2 ) {
            return `width: ${columnWidth2}px;`
        }
        if( props.index === 3 ) {
            return `width: ${columnWidth3}px;`
        }
        if( props.index === 4 ) {
            return `width: ${columnWidth4}px;`
        }
        return ""
    } }
    ${ props => ( props.clickable === true ) ? "cursor: pointer;" : "" }
    &:hover {
      background-color: #ccc;
      color: white;
      cursor: pointer;
    }
`

export const Close = styled.div`
    display:block;
    box-sizing:border-box;
    width:20px;
    height:20px;
    border-width:3px;
    border-style: solid;
    border-color:Black;
    border-radius:100%;
    background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);
    background-color:Black;
    box-shadow:0px 0px 5px 2px rgba(0,0,0,0.5);
    transition: all 0.3s ease;
    cursor:pointer;
`